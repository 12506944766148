@charset "UTF-8";
.animated {
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both; }

.animated.infinite {
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite; }

.animated.delay-0\.25s {
  -webkit-animation-delay: 0.25s;
          animation-delay: 0.25s; }

.animated.delay-0\.5s {
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s; }

.animated.delay-1s {
  -webkit-animation-delay: 1s;
          animation-delay: 1s; }

.animated.delay-0\.75s {
  -webkit-animation-delay: 0.75s;
          animation-delay: 0.75s; }

.animated.delay-1\.25s {
  -webkit-animation-delay: 1.25s;
          animation-delay: 1.25s; }

.animated.delay-1\.5s {
  -webkit-animation-delay: 1.5s;
          animation-delay: 1.5s; }

.animated.delay-2s {
  -webkit-animation-delay: 2s;
          animation-delay: 2s; }

.animated.delay-2\.5s {
  -webkit-animation-delay: 2.5s;
          animation-delay: 2.5s; }

.animated.delay-3s {
  -webkit-animation-delay: 3s;
          animation-delay: 3s; }

.animated.fast {
  -webkit-animation-duration: 800ms;
          animation-duration: 800ms; }

.animated.faster {
  -webkit-animation-duration: 500ms;
          animation-duration: 500ms; }

.animated.slow {
  -webkit-animation-duration: 2s;
          animation-duration: 2s; }

.animated.slower {
  -webkit-animation-duration: 3s;
          animation-duration: 3s; }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.fadeIn {
  -webkit-animation-name: fadeIn;
          animation-name: fadeIn; }

@-webkit-keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fadeOut {
  -webkit-animation-name: fadeOut;
          animation-name: fadeOut; }

@-webkit-keyframes zoomIn {
  from {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
            transform: scale3d(0.9, 0.9, 0.9); }
  50% { }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1); } }

@keyframes zoomIn {
  from {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
            transform: scale3d(0.9, 0.9, 0.9); }
  50% { }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1); } }

.zoomIn {
  -webkit-animation-name: zoomIn;
          animation-name: zoomIn; }

@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-10%, 0, 0);
            transform: translate3d(-10%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-10%, 0, 0);
            transform: translate3d(-10%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
          animation-name: fadeInLeft; }

@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(10%, 0, 0);
            transform: translate3d(10%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(10%, 0, 0);
            transform: translate3d(10%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

.fadeInRight {
  -webkit-animation-name: fadeInRight;
          animation-name: fadeInRight; }

@-webkit-keyframes fadeInLeftFull {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@keyframes fadeInLeftFull {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

.fadeInLeftFull {
  -webkit-animation-name: fadeInLeftFull;
          animation-name: fadeInLeftFull; }

@-webkit-keyframes fadeInRightFull {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@keyframes fadeInRightFull {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

.fadeInRightFull {
  -webkit-animation-name: fadeInRightFull;
          animation-name: fadeInRightFull; }

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 10%, 0);
            transform: translate3d(0, 10%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 10%, 0);
            transform: translate3d(0, 10%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

.fadeInUp {
  -webkit-animation-name: fadeInUp;
          animation-name: fadeInUp; }

@-webkit-keyframes flash {
  from,
  50%,
  to {
    opacity: 1; }
  25%,
  75% {
    opacity: 0; } }

@keyframes flash {
  from,
  50%,
  to {
    opacity: 1; }
  25%,
  75% {
    opacity: 0; } }

.flash {
  -webkit-animation-name: flash;
          animation-name: flash; }

@-webkit-keyframes rotateIn {
  from {
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, -200deg);
            transform: rotate3d(0, 0, 1, -200deg); }
  to {
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@keyframes rotateIn {
  from {
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, -200deg);
            transform: rotate3d(0, 0, 1, -200deg); }
  to {
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@font-face {
  font-family: 'RalewayRegular';
  src: url("../fonts/Raleway/RalewayRegular.eot");
  src: url("../fonts/Raleway/RalewayRegular.woff2") format("woff2"), url("../fonts/Raleway/RalewayRegular.woff") format("woff"), url("../fonts/Raleway/RalewayRegular.ttf") format("truetype"), url("../fonts/Raleway/RalewayRegular.svg#RalewayRegular") format("svg"), url("../fonts/Raleway/RalewayRegular.eot?#iefix") format("embedded-opentype"); }

@font-face {
  font-family: 'RalewayMedium';
  src: url("../fonts/Raleway/Raleway-Medium.eot");
  src: url("../fonts/Raleway/Raleway-Medium.woff2") format("woff2"), url("../fonts/Raleway/Raleway-Medium.woff") format("woff"), url("../fonts/Raleway/Raleway-Medium.ttf") format("truetype"), url("../fonts/Raleway/Raleway-Medium.svg#Raleway-Medium") format("svg"), url("../fonts/Raleway/Raleway-Medium.eot?#iefix") format("embedded-opentype");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'RalewaySemiBold';
  src: url("../fonts/Raleway/Raleway-SemiBold.eot");
  src: url("../fonts/Raleway/Raleway-SemiBold.woff2") format("woff2"), url("../fonts/Raleway/Raleway-SemiBold.woff") format("woff"), url("../fonts/Raleway/Raleway-SemiBold.ttf") format("truetype"), url("../fonts/Raleway/Raleway-SemiBold.svg#Raleway-SemiBold") format("svg"), url("../fonts/Raleway/Raleway-SemiBold.eot?#iefix") format("embedded-opentype");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'RalewayBoldItalic';
  src: url("../fonts/Raleway/RalewayBoldItalic.eot");
  src: url("../fonts/Raleway/RalewayBoldItalic.woff2") format("woff2"), url("../fonts/Raleway/RalewayBoldItalic.woff") format("woff"), url("../fonts/Raleway/RalewayBoldItalic.ttf") format("truetype"), url("../fonts/Raleway/RalewayBoldItalic.svg#RalewayBoldItalic") format("svg"), url("../fonts/Raleway/RalewayBoldItalic.eot?#iefix") format("embedded-opentype"); }

@font-face {
  font-family: 'MullerRegular';
  src: url("../fonts/Muller/MullerRegular.eot");
  src: url("../fonts/Muller/MullerRegular.woff2") format("woff2"), url("../fonts/Muller/MullerRegular.woff") format("woff"), url("../fonts/Muller/MullerRegular.ttf") format("truetype"), url("../fonts/Muller/MullerRegular.svg#MullerRegular") format("svg"), url("../fonts/Muller/MullerRegular.eot?#iefix") format("embedded-opentype"); }

@font-face {
  font-family: 'MullerRegularItalic';
  src: url("../fonts/Muller/MullerRegularItalic.eot");
  src: url("../fonts/Muller/MullerRegularItalic.woff2") format("woff2"), url("../fonts/Muller/MullerRegularItalic.woff") format("woff"), url("../fonts/Muller/MullerRegularItalic.ttf") format("truetype"), url("../fonts/Muller/MullerRegularItalic.svg#MullerRegularItalic") format("svg"), url("../fonts/Muller/MullerRegularItalic.eot?#iefix") format("embedded-opentype"); }

@font-face {
  font-family: 'MullerMedium';
  src: url("../fonts/Muller/MullerMedium.eot");
  src: url("../fonts/Muller/MullerMedium.woff2") format("woff2"), url("../fonts/Muller/MullerMedium.woff") format("woff"), url("../fonts/Muller/MullerMedium.ttf") format("truetype"), url("../fonts/Muller/MullerMedium.svg#MullerMedium") format("svg"), url("../fonts/Muller/MullerMedium.eot?#iefix") format("embedded-opentype"); }

@font-face {
  font-family: 'MullerBold';
  src: url("../fonts/Muller/MullerBold.eot");
  src: url("../fonts/Muller/MullerBold.woff2") format("woff2"), url("../fonts/Muller/MullerBold.woff") format("woff"), url("../fonts/Muller/MullerBold.ttf") format("truetype"), url("../fonts/Muller/MullerBold.svg#MullerBold") format("svg"), url("../fonts/Muller/MullerBold.eot?#iefix") format("embedded-opentype"); }

*:after,
*:before,
* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }

body, div, dl, dt, dd, ul, li, h1, h2, h3, h4, h5, h6, pre, code, form, fieldset, input, textarea, p, blockquote, th, td, figcaption, figure {
  margin: 0;
  padding: 0; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

fieldset, img, abbr {
  border: 0; }

address, caption, cite, code, dfn, em, strong, th, var {
  font-style: normal;
  font-weight: normal; }

ul li {
  list-style: none; }

caption, th {
  text-align: left; }

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: normal; }

sup {
  vertical-align: text-top; }

sub {
  vertical-align: text-bottom; }

input, textarea, select {
  -webkit-appearance: none;
  -moz-appearance: none;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  outline: 0; }

legend {
  color: #000; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

img {
  max-width: 100%;
  display: block; }

html,
body {
  height: 100%; }

body {
  font-family: 'RalewayRegular', Arial, sans-serif;
  font-size: 16px;
  line-height: 26px;
  font-weight: normal;
  color: #313439; }

a {
  text-decoration: none;
  color: #333333; }

img {
  max-width: 100%;
  height: auto; }

embed,
object,
iframe,
video {
  width: 100%;
  height: auto; }

h1 {
  font-family: 'MullerBold', Arial, sans-serif;
  font-weight: normal; }

h2, h3, h4 {
  font-family: 'MullerMedium', Arial, sans-serif;
  font-weight: normal; }

input::-webkit-input-placeholder {
  opacity: 1;
  -webkit-transition: opacity .25s linear;
  -o-transition: opacity .25s linear;
  transition: opacity .25s linear; }

input:-ms-input-placeholder {
  opacity: 1;
  -webkit-transition: opacity .25s linear;
  -o-transition: opacity .25s linear;
  transition: opacity .25s linear; }

input::-ms-input-placeholder {
  opacity: 1;
  -webkit-transition: opacity .25s linear;
  -o-transition: opacity .25s linear;
  transition: opacity .25s linear; }

input::placeholder {
  opacity: 1;
  -webkit-transition: opacity .25s linear;
  -o-transition: opacity .25s linear;
  transition: opacity .25s linear; }

textarea::-webkit-input-placeholder {
  opacity: 1;
  -webkit-transition: opacity .25s linear;
  -o-transition: opacity .25s linear;
  transition: opacity .25s linear; }

textarea:-ms-input-placeholder {
  opacity: 1;
  -webkit-transition: opacity .25s linear;
  -o-transition: opacity .25s linear;
  transition: opacity .25s linear; }

textarea::-ms-input-placeholder {
  opacity: 1;
  -webkit-transition: opacity .25s linear;
  -o-transition: opacity .25s linear;
  transition: opacity .25s linear; }

textarea::placeholder {
  opacity: 1;
  -webkit-transition: opacity .25s linear;
  -o-transition: opacity .25s linear;
  transition: opacity .25s linear; }

.comment-textarea::-webkit-input-placeholder {
  font-family: 'Muller', Arial, sans-serif; }

.comment-textarea:-ms-input-placeholder {
  font-family: 'Muller', Arial, sans-serif; }

.comment-textarea::-ms-input-placeholder {
  font-family: 'Muller', Arial, sans-serif; }

.comment-textarea::placeholder {
  font-family: 'Muller', Arial, sans-serif; }

input:focus::-webkit-input-placeholder,
textarea:focus::-webkit-input-placeholder {
  opacity: 0;
  -webkit-transition: opacity .25s linear;
  -o-transition: opacity .25s linear;
  transition: opacity .25s linear; }

input:focus:-ms-input-placeholder,
textarea:focus:-ms-input-placeholder {
  opacity: 0;
  -webkit-transition: opacity .25s linear;
  -o-transition: opacity .25s linear;
  transition: opacity .25s linear; }

input:focus::-ms-input-placeholder,
textarea:focus::-ms-input-placeholder {
  opacity: 0;
  -webkit-transition: opacity .25s linear;
  -o-transition: opacity .25s linear;
  transition: opacity .25s linear; }

input:focus::placeholder,
textarea:focus::placeholder {
  opacity: 0;
  -webkit-transition: opacity .25s linear;
  -o-transition: opacity .25s linear;
  transition: opacity .25s linear; }

input::-webkit-search-decoration,
input::-webkit-search-cancel-button,
input::-webkit-search-results-button,
input::-webkit-search-results-decoration {
  display: none;
  width: 0;
  height: 0; }

.clearfix::after {
  content: '';
  display: table;
  clear: both; }

.backgrounds-store,
.svg-store {
  display: none; }

*:active, *:focus {
  outline: 0 !important;
  outline: none !important;
  -webkit-tap-highlight-color: transparent !important; }

.inner-center {
  text-align: center; }

.inner-right {
  text-align: right; }

ul.inline-list li {
  display: inline-block;
  vertical-align: middle; }

.wrapper {
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
  height: 100%;
  max-width: 1200px; }

.wrapper-small {
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
  height: 100%;
  max-width: 830px; }

.flex-grid {
  margin: 0 -15px; }

.flex-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap; }

.flex-no-wrap {
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap; }

.flex-group {
  margin-left: -15px;
  margin-right: -15px; }

.flex-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  width: 100%;
  height: 100%; }

.center-content {
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 auto;
      -ms-flex: 1 0 auto;
          flex: 1 0 auto; }

.footer-content {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto; }

.content-between {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between; }

.content-around {
  -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
          justify-content: space-around; }

.content-center {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center; }

.items-top {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
      -ms-flex-align: start;
          align-items: flex-start; }

.items-center {
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center; }

.one-item {
  width: 100%;
  padding: 0 15px; }

.two-items {
  width: 50%;
  padding: 0 15px; }

.three-items {
  width: 33.3333%;
  padding: 0 15px; }

.four-items {
  width: 25%;
  padding: 0 15px; }

.five-items {
  width: 20%;
  padding: 0 15px; }

.start-item {
  -webkit-box-ordinal-group: 0;
  -webkit-order: -1;
      -ms-flex-order: -1;
          order: -1; }

.end-item {
  -webkit-box-ordinal-group: 2;
  -webkit-order: 1;
      -ms-flex-order: 1;
          order: 1; }

.reverse-items {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: row-reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse; }

.button-center {
  text-align: center; }

.button {
  font-family: 'MullerBold', Arial, sans-serif;
  font-size: 14px;
  line-height: 50px;
  text-align: center;
  height: 50px;
  -webkit-border-radius: 25px;
          border-radius: 25px;
  display: inline-block;
  vertical-align: middle;
  padding: 0 35px;
  text-transform: uppercase;
  border: 1px solid transparent;
  cursor: pointer; }

.button_green {
  background-color: #2ecc71;
  color: #ffffff;
  border: 1px solid #2ecc71;
  -webkit-transition: all .25s ease-in;
  -o-transition: all .25s ease-in;
  transition: all .25s ease-in; }

.button_green:hover {
  background-color: transparent;
  color: #2ecc71;
  -webkit-transition: all .25s ease-in;
  -o-transition: all .25s ease-in;
  transition: all .25s ease-in;
  -webkit-box-shadow: none;
          box-shadow: none; }

.button_green.with_shadow {
  -webkit-box-shadow: 0px 0px 25px 0px rgba(46, 204, 113, 0.5);
          box-shadow: 0px 0px 25px 0px rgba(46, 204, 113, 0.5); }

.button_orange {
  background-color: #f39c12;
  color: #ffffff;
  border: 1px solid #f39c12;
  -webkit-transition: all .25s ease-in;
  -o-transition: all .25s ease-in;
  transition: all .25s ease-in; }

.button_orange:hover {
  background-color: transparent;
  color: #f39c12;
  -webkit-transition: all .25s ease-in;
  -o-transition: all .25s ease-in;
  transition: all .25s ease-in; }

.button_orange.dark_hover {
  -webkit-box-shadow: 0px 5px 25px 0px rgba(243, 187, 18, 0.5);
          box-shadow: 0px 5px 25px 0px rgba(243, 187, 18, 0.5); }

.button_orange.dark_hover:hover {
  background-color: #313439;
  border-color: #313439;
  color: #fff;
  -webkit-box-shadow: none;
          box-shadow: none; }

.button_transparent {
  background-color: transparent;
  border: 1px solid #e0dfdb; }

.button_facebook {
  font-family: 'RalewayRegular', Arial, sans-serif;
  color: #3670ac;
  border: 1px solid #3670ac;
  text-transform: none;
  width: 150px;
  padding: 0;
  line-height: 47px;
  -webkit-transition: all .25s ease-in;
  -o-transition: all .25s ease-in;
  transition: all .25s ease-in; }

.button_facebook svg {
  fill: #3670ac; }

.button_facebook:hover {
  background-color: #3670ac;
  color: #ffffff;
  -webkit-transition: all .25s ease-in;
  -o-transition: all .25s ease-in;
  transition: all .25s ease-in; }

.button_facebook:hover svg {
  fill: #ffffff; }

.button_twitter {
  font-family: 'RalewayRegular', Arial, sans-serif;
  color: #00bcf4;
  border: 1px solid #00bcf4;
  text-transform: none;
  width: 150px;
  padding: 0;
  line-height: 47px;
  -webkit-transition: all .25s ease-in;
  -o-transition: all .25s ease-in;
  transition: all .25s ease-in; }

.button_twitter svg {
  fill: #00bcf4;
  height: 13px;
  width: 16px; }

.button_twitter:hover {
  background-color: #00bcf4;
  color: #ffffff;
  -webkit-transition: all .25s ease-in;
  -o-transition: all .25s ease-in;
  transition: all .25s ease-in; }

.button_twitter:hover svg {
  fill: #ffffff; }

.button-reg {
  -webkit-border-radius: 50px;
          border-radius: 50px;
  max-width: 190px;
  width: 100%;
  padding: 10px 15px;
  font-size: 12px;
  line-height: 12px;
  -webkit-transition: all .25s ease-in;
  -o-transition: all .25s ease-in;
  transition: all .25s ease-in;
  text-transform: none;
  color: #ffffff;
  font-family: "RalewayRegular", "Open Sans", sans-serif;
  display: block; }
  .button-reg:hover {
    opacity: 0.7;
    -webkit-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
    transition: all .25s ease-out; }

.button-reg_facebook {
  background-color: #3a559f; }

.button-reg_telegram {
  background-color: #50abf1; }

.button__icon,
.button__text {
  display: inline-block;
  vertical-align: middle; }

.button__icon {
  margin-right: 5px; }

.form-button {
  margin-top: 10px;
  height: 45px;
  line-height: 45px;
  width: 210px; }

.pagination-block {
  padding: 60px 0;
  text-align: center; }

.pagination-nav {
  display: inline-block; }

.pagination-nav ul li {
  text-transform: uppercase; }

.pagination-nav ul li {
  display: inline-block;
  vertical-align: middle;
  color: #313439;
  font-size: 18px;
  line-height: 52px;
  -webkit-border-radius: 5px;
          border-radius: 5px;
  border: 1px solid #e0e1e1;
  height: 50px;
  width: 50px;
  -webkit-transition: all .25s ease-in;
  -o-transition: all .25s ease-in;
  transition: all .25s ease-in; }

.pagination-nav ul li + li {
  margin-left: 5px; }

.pagination-nav ul li a {
  font-family: 'MullerBold', Arial, sans-serif;
  font-size: 18px;
  display: block;
  height: 50px;
  width: 50px;
  -webkit-border-radius: 5px;
          border-radius: 5px;
  -webkit-transition: all .25s ease-in;
  -o-transition: all .25s ease-in;
  transition: all .25s ease-in; }

.pagination-nav ul li:hover,
.pagination-nav ul li.page-active {
  border: 1px solid #2ecc71;
  -webkit-transition: all .25s ease-in;
  -o-transition: all .25s ease-in;
  transition: all .25s ease-in; }

.pagination-nav ul li:hover a,
.pagination-nav ul li.page-active a {
  background-color: #2ecc71;
  color: #fff;
  -webkit-transition: all .25s ease-in;
  -o-transition: all .25s ease-in;
  transition: all .25s ease-in; }

.pagination-nav .arrow-left,
.pagination-nav .arrow-right {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 6.4%; }

.pagination-nav .arrow-left {
  -ms-transform: rotate(135deg);
      transform: rotate(135deg);
  -webkit-transform: rotate(135deg); }

.pagination-nav .arrow-right {
  -ms-transform: rotate(-45deg);
      transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.breadcrumbs-block {
  padding: 20px 0; }

.breadcrumbs-nav ul li {
  display: inline-block; }

.breadcrumbs-nav ul li {
  position: relative; }

.breadcrumbs-nav ul li::after {
  content: '';
  display: inline-block;
  vertical-align: middle;
  width: 7px;
  height: 7px;
  font-size: 13px;
  line-height: 13px;
  border-right: 2px solid #818284;
  border-bottom: 2px solid #818284;
  -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
          transform: rotate(-45deg);
  margin: 0 10px; }

.breadcrumbs-nav ul li,
.breadcrumbs-nav ul li a {
  color: #313439;
  font-size: 14px;
  line-height: 20px;
  font-family: "MullerRegular", "Open Sans", sans-serif; }

.breadcrumbs-nav ul li a {
  opacity: 0.6; }

.breadcrumbs-nav ul li:last-child {
  opacity: 1; }

.breadcrumbs-nav ul li:last-child::after {
  display: none; }

.checkbox[type="checkbox"]:checked,
.checkbox[type="checkbox"]:not(:checked) {
  position: absolute;
  left: -9999px; }

.checkbox[type="checkbox"]:checked + label,
.checkbox[type="checkbox"]:not(:checked) + label,
.checkbox[type="radio"]:checked + label,
.checkbox[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block; }

.checkbox[type="checkbox"]:checked + label:before,
.checkbox[type="radio"]:checked + label:before,
.checkbox[type="checkbox"]:not(:checked) + label:before,
.checkbox[type="radio"]:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 1px solid #ddd;
  background: #fff;
  -webkit-border-radius: 4px;
          border-radius: 4px; }

.checkbox[type="checkbox"]:checked + label:before,
.checkbox[type="radio"]:checked + label:before {
  background: #f39c12;
  border: 1px solid #f39c12; }

.checkbox[type="checkbox"]:checked + label:after,
.checkbox[type="checkbox"]:not(:checked) + label:after,
.checkbox[type="radio"]:checked + label:after,
.checkbox[type="radio"]:not(:checked) + label:after {
  content: '';
  width: 6px;
  height: 9px;
  border-bottom: 2px solid #ffffff;
  border-right: 2px solid #ffffff;
  position: absolute;
  top: 5px;
  left: 7px;
  -webkit-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease; }

.checkbox[type="checkbox"]:not(:checked) + label:after,
.checkbox[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0) rotate(45deg);
      -ms-transform: scale(0) rotate(45deg);
          transform: scale(0) rotate(45deg); }

.checkbox[type="checkbox"]:checked + label:after,
.checkbox[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1) rotate(45deg);
      -ms-transform: scale(1) rotate(45deg);
          transform: scale(1) rotate(45deg); }

.radio[type="radio"]:checked,
.radio[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px; }

.radio[type="radio"]:checked + label,
.radio[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666; }

.radio[type="radio"]:checked + label:before,
.radio[type="radio"]:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  -webkit-border-radius: 100%;
          border-radius: 100%;
  background: #fff; }

.radio[type="radio"]:checked + label:after,
.radio[type="radio"]:not(:checked) + label:after {
  content: '';
  width: 12px;
  height: 12px;
  background: #F87DA9;
  position: absolute;
  top: 4px;
  left: 4px;
  -webkit-border-radius: 100%;
          border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease; }

.radio[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
      transform: scale(0); }

.radio[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
      transform: scale(1); }

.drop-container {
  width: 100%;
  cursor: pointer;
  position: relative;
  color: #515357;
  font-size: 14px;
  font-family: "MullerRegular", "Open Sans", sans-serif; }
  .drop-container:after {
    height: 7px;
    width: 7px;
    border-bottom: 2px solid #313439;
    border-right: 2px solid #313439;
    content: '';
    display: block;
    margin-top: -4px;
    pointer-events: none;
    position: absolute;
    right: 12px;
    top: 50%;
    -webkit-transform-origin: 66% 66%;
        -ms-transform-origin: 66% 66%;
            transform-origin: 66% 66%;
    -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
            transform: rotate(45deg);
    -webkit-transition: all 0.15s ease-in-out;
    -o-transition: all 0.15s ease-in-out;
    transition: all 0.15s ease-in-out; }

.drop-container .countr-icon {
  margin-right: 5px; }

.droplist {
  background-color: #fff;
  position: absolute;
  top: -webkit-calc(100% + 2px);
  top: calc(100% + 2px);
  border: solid 1px #e8e8e8;
  -webkit-border-radius: 5px;
          border-radius: 5px;
  width: 100%;
  display: none;
  z-index: 3; }

.droplist ul li a {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  height: 35px;
  padding: 0 20px;
  color: #313439; }
  .droplist ul li a:hover {
    background-color: #f4f5f5; }

.dropbtn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  line-height: 23px; }

.dropbtn.open + .droplist {
  display: block; }

/* header */
.logo {
  display: inline-block;
  height: 40px; }

.menu ul li {
  display: inline-block;
  border-bottom: 1px solid transparent;
  padding-bottom: 5px;
  -webkit-transition: all .25s linear;
  -o-transition: all .25s linear;
  transition: all .25s linear;
  position: relative; }
  .menu ul li:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    height: 110px; }

.menu ul li:hover {
  border-bottom: 1px solid #2ecc71;
  -webkit-transition: all .25s linear;
  -o-transition: all .25s linear;
  transition: all .25s linear; }

.menu ul li + li {
  margin-left: 35px; }

.menu ul li a {
  font-size: 14px;
  line-height: 14px;
  color: #ffffff;
  display: block;
  position: relative;
  z-index: 3; }

.menu ul li {
  display: inline-block; }

.menu-header {
  margin-left: 12%; }

.menu-header ul li {
  padding: 0 20px; }
  @media screen and (max-width: 991px) {
    .menu-header ul li {
      padding: 0 5px; } }
  .menu-header ul li:before {
    content: '';
    display: block;
    position: absolute;
    top: -48px;
    left: 0;
    right: 0;
    height: 110px;
    -webkit-transition: all .25s linear;
    -o-transition: all .25s linear;
    transition: all .25s linear; }
  .menu-header ul li:hover::before {
    -webkit-transition: all .25s linear;
    -o-transition: all .25s linear;
    transition: all .25s linear;
    background-color: #f39c12; }

.menu-header ul li + li {
  margin-left: 0; }

.menu-header ul li:hover {
  border-bottom: none;
  -webkit-transition: all .25s linear;
  -o-transition: all .25s linear;
  transition: all .25s linear; }

.header {
  background-color: #313439;
  padding: 30px 0; }

.header-social-list {
  display: inline-block;
  vertical-align: middle;
  margin-right: 30px; }

.social-list li a {
  height: 50px;
  width: 50px;
  display: block;
  border: 1px solid #64666a;
  -webkit-border-radius: 100%;
          border-radius: 100%;
  text-align: center;
  line-height: 53px;
  -webkit-transition: border .25s ease-in;
  -o-transition: border .25s ease-in;
  transition: border .25s ease-in; }

.social-list li a:hover {
  border: 1px solid #ffffff;
  -webkit-transition: border .25s ease-in;
  -o-transition: border .25s ease-in;
  transition: border .25s ease-in; }

.social-list li + li {
  margin-left: 2px; }

.footer-block__bottom {
  background-color: #1b1c1f;
  padding: 25px 0; }
  @media screen and (max-width: 375px) {
    .footer-block__bottom {
      padding: 20px 0; } }

.footer-block__bottom span a,
.footer-block__bottom span {
  opacity: 0.8;
  font-size: 14px;
  line-height: 14px;
  color: #fff; }

@media screen and (max-width: 375px) {
  .footer-block__bottom span {
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
        -ms-flex-order: 3;
            order: 3;
    margin-bottom: 7px; } }

@media screen and (max-width: 576px) {
  .footer-group-links {
    margin-bottom: 15px; } }

@media screen and (max-width: 375px) {
  .footer-group-links {
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
        -ms-flex-order: 2;
            order: 2;
    width: 100%;
    text-align: center; } }

.footer-group-links a {
  display: inline-block;
  vertical-align: middle;
  margin-right: 20px; }
  .footer-group-links a:last-child {
    margin-right: 0; }

/* end */
/* footer */
.footer-block__top {
  background-color: #313439;
  padding: 30px 0; }
  @media screen and (max-width: 870px) {
    .footer-block__top {
      padding: 30px 0 20px; } }
  @media screen and (max-width: 375px) {
    .footer-block__top {
      padding: 20px 0 10px; } }

.footer-block .logo-footer {
  -webkit-box-ordinal-group: 2;
  -webkit-order: 1;
      -ms-flex-order: 1;
          order: 1; }

.footer-block .menu-footer {
  -webkit-box-ordinal-group: 3;
  -webkit-order: 2;
      -ms-flex-order: 2;
          order: 2; }

.footer-block .footer-social-list {
  -webkit-box-ordinal-group: 4;
  -webkit-order: 3;
      -ms-flex-order: 3;
          order: 3; }

@media screen and (max-width: 870px) {
  .footer-block {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; }
    .footer-block .menu-footer {
      -webkit-box-ordinal-group: 5;
      -webkit-order: 4;
          -ms-flex-order: 4;
              order: 4;
      width: 100%;
      text-align: center;
      padding-top: 25px; } }

@media screen and (max-width: 470px) {
  .footer-block {
    position: relative; }
    .footer-block .menu-footer {
      -webkit-box-ordinal-group: 4;
      -webkit-order: 3;
          -ms-flex-order: 3;
              order: 3;
      padding-top: 0;
      margin-top: 15px;
      width: 100%; }
      .footer-block .menu-footer ul li + li {
        margin-left: 20px; }
    .footer-block .footer-social-list {
      position: absolute;
      top: 0;
      right: 0; }
      .footer-block .footer-social-list li a {
        width: 35px;
        height: 35px;
        line-height: 38px; } }

@media screen and (max-width: 375px) {
  .footer-block .menu-footer ul li a {
    font-size: 13px; }
  .footer-block .menu-footer ul li + li {
    margin-left: 15px; } }

@media screen and (max-width: 335px) {
  .footer-block .menu-footer ul li + li {
    margin-left: 9px; } }

.footer-block__top a {
  color: #fff;
  font-size: 14px;
  line-height: 14px; }

.copy {
  font-size: 12px;
  line-height: 19px;
  color: #ffffff;
  opacity: .6; }

/* end */
/* banner */
.banner-section {
  background-image: url(../images/article/article-bg.jpg);
  background-repeat: no-repeat;
  background-position: 50%;
  -webkit-background-size: cover;
          background-size: cover;
  position: relative;
  z-index: 0;
  text-align: center;
  color: #ffffff;
  padding: 120px 0 70px; }

.banner-section::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: #404040;
  opacity: 0.5;
  z-index: 1; }

.banner-section .wrapper {
  position: relative;
  z-index: 3; }

.h1 {
  position: relative;
  z-index: 3;
  font-size: 48px;
  line-height: 60px;
  font-family: 'MullerBold', Arial, sans-serif; }

.banner-items {
  margin-top: 40px;
  position: relative;
  z-index: 3; }

.banner-item {
  display: inline-block;
  vertical-align: middle;
  line-height: 14px; }

.banner-item + .banner-item {
  margin-left: 30px; }

.time-post__text,
.time-post__icon {
  display: inline-block;
  vertical-align: middle;
  line-height: 14px; }

.star svg {
  fill: #ffffff; }

.star-selected svg,
.star.active svg {
  fill: #f3c112; }

/* end */
/* article */
.grey-section {
  background-color: #F4F5F5; }

.blog_wrap,
.article-wrap {
  max-width: 800px;
  width: 67%;
  padding: 0 15px; }

.article-inner p {
  margin: 35px 0; }

.article-inner h2 {
  font-family: 'MullerMedium' Arial, sans-serif;
  font-size: 30px;
  line-height: 26px;
  margin-top: 50px; }

.article-inner img {
  margin: 45px 0; }

.article-inner ul {
  margin: 35px 0; }

.article-inner ul li {
  position: relative;
  padding-left: 20px; }

.article-inner ul li + li {
  margin-top: 20px; }

.article-inner ul li::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 8px;
  height: 8px;
  background-color: #2e69cc;
  -webkit-border-radius: 100%;
          border-radius: 100%;
  -webkit-transform: translateY(100%);
    -webkit-transform-transform: translateY(100%); }

.article-inner a {
  color: #f39c12;
  border-bottom: 1px dashed #f39c12;
  -webkit-transition: all .25s ease-in;
  -o-transition: all .25s ease-in;
  transition: all .25s ease-in; }

.article-inner a:hover {
  border-bottom: 1px solid #f39c12;
  -webkit-transition: all .25s ease-out;
  -o-transition: all .25s ease-out;
  transition: all .25s ease-out; }

.article-inner blockquote {
  width: 100%;
  padding: 40px 39px 40px 200px;
  position: relative;
  background-color: #fff;
  -webkit-border-radius: 3px;
          border-radius: 3px;
  font-family: 'MullerRegularItalic', Arial, sans-serif;
  font-size: 18px;
  line-height: 28px; }

.article-inner blockquote::before {
  content: '\201C';
  display: block;
  font-family: 'RalewayBoldItalic', Arial, sans-serif;
  font-size: 200px;
  line-height: 152px;
  position: absolute;
  top: 46px;
  left: 55px; }

.advertising-image {
  position: relative; }

.advertising-image img {
  position: absolute;
  z-index: 3;
  left: 30px;
  bottom: 0;
  margin: 0; }

.advertising-item {
  background-color: #fff;
  padding: 35px 70px 35px 300px;
  position: relative;
  background-repeat: no-repeat;
  background-position: 0 0;
  overflow: hidden;
  -webkit-border-radius: 3px;
          border-radius: 3px;
  -webkit-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
    -webkit-box-shadow-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05); }

.advertising-item::before {
  content: '';
  display: block;
  width: 290px;
  height: 290px;
  position: absolute;
  top: 105px;
  left: -25px;
  -webkit-border-radius: 100%;
          border-radius: 100%;
  background-color: #2ecc71; }

.advertising-item h3 {
  font-family: 'MullerBold', Arial, sans-serif;
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 30px;
  text-transform: uppercase; }

.advertising-item p {
  margin: 0; }

/* end */
/* forms */
.info-section {
  background-image: url("../images/article/form-bg.jpg");
  background-repeat: no-repeat;
  background-position: 50%;
  -webkit-background-size: cover;
          background-size: cover;
  padding: 40px 30px;
  position: relative;
  margin-top: 60px; }

.gradient-overlay {
  position: relative;
  z-index: 1; }

.gradient-overlay::before {
  content: '';
  background: -webkit-gradient(linear, left top, left bottom, from(#2ECC71), to(#2E69CC));
  background: -webkit-linear-gradient(#2ECC71, #2E69CC);
  background: -o-linear-gradient(#2ECC71, #2E69CC);
  background: linear-gradient(#2ECC71, #2E69CC);
  z-index: 2;
  position: absolute;
  opacity: 0.75;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%; }

.info-form-block {
  z-index: 3;
  position: relative;
  color: #ffffff; }

.input-item {
  width: 100%;
  background-color: #fff;
  color: #000;
  height: 50px;
  padding: 0 30px; }

.info-form-input {
  border: 1px solid transparent;
  -webkit-border-radius: 25px;
          border-radius: 25px; }

.form-title {
  font-family: 'MullerBold', Arial, sans-serif;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 26px; }

/* end */
/* article element */
.article-elements-section {
  margin-top: 50px;
  padding-top: 18px;
  border-top: 3px solid #DFE0E1; }

.article-elements-list li .article-elements__views-text,
.article-elements-list li time {
  opacity: 0.6; }

.article-elements-list li {
  margin-right: 20px; }

.article-elements-list li:last-child {
  opacity: 0.4; }

.article-elements-list li::after {
  content: '';
  display: inline-block;
  width: 1px;
  height: 14px;
  margin-left: 20px;
  background-color: #D4D5D6; }

.article-elements-list li:last-child::after {
  display: none; }

.article-elements__views-icon,
.article-elements__views-text {
  display: inline-block;
  vertical-align: middle;
  line-height: 14px; }

.article-elements__bottom-group {
  margin-top: 40px; }

.raitings-list .star svg {
  stroke-width: 15px;
  stroke: #f3c112;
  -webkit-transition: all .25s linear;
  -o-transition: all .25s linear;
  transition: all .25s linear; }

.raitings-list .star:hover svg {
  cursor: pointer;
  fill: #f3c112 !important;
  -webkit-transition: all .25s linear;
  -o-transition: all .25s linear;
  transition: all .25s linear; }

.raitings-list li {
  margin-left: 5px; }

.raitings-list li:first-child {
  margin-left: 0; }

.article-elements__bottom-raiting-title {
  font-family: 'MullerRegular', Arial, sans-serif;
  font-size: 18px;
  line-height: 18px;
  margin-bottom: 30px; }

.article-elements__bottom-like-title {
  font-family: 'MullerMedium', Arial, sans-serif;
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 5px; }

.article-elements__bottom-like-subtitle {
  font-family: 'MullerRegular', Arial, sans-serif;
  font-size: 18px;
  line-height: 18px;
  margin-bottom: 18px; }

.article-elements__bottom-item:last-child {
  padding: 0 15px; }

.article-elements__bottom-item .button_twitter {
  margin-left: 6px; }

.article-share__text,
.article-share__icon {
  display: inline-block;
  vertical-align: middle;
  line-height: 14px; }

.button_twitter .article-share__icon .article-share__icon svg {
  -webkit-transition: all .25s linear;
  -o-transition: all .25s linear;
  transition: all .25s linear; }

.button_twitter .article-share__icon:hover .article-share__icon svg {
  fill: #fff !important;
  -webkit-transition: all .25s linear;
  -o-transition: all .25s linear;
  transition: all .25s linear; }

/* end */
/* related posts */
.related-posts-section {
  padding-top: 70px;
  padding-bottom: 100px; }

.h3 {
  font-size: 30px;
  line-height: 38px;
  font-family: 'MullerMedium', Arial, sans-serif; }

.related-posts-block {
  margin-top: 0; }

.related-item {
  margin-top: 60px; }

.post-card:hover .post-card__media {
  -webkit-transition: all .25s ease-in;
  -o-transition: all .25s ease-in;
  transition: all .25s ease-in; }

.post-card__media {
  -webkit-border-top-left-radius: inherit;
          border-top-left-radius: inherit;
  -webkit-border-top-right-radius: inherit;
          border-top-right-radius: inherit;
  position: relative;
  -webkit-box-sizing: border-box;
    -webkit-box-sizing-box-sizing: border-box;
  background-repeat: no-repeat;
  background-position: 50%;
  -webkit-background-size: cover;
          background-size: cover;
  -webkit-transition: all .25s ease-in;
  -o-transition: all .25s ease-in;
  transition: all .25s ease-in; }

.post-card__media::before {
  content: '';
  display: block;
  padding-top: 200px; }

.post-card__time {
  position: absolute;
  top: 20px;
  left: 20px;
  background-color: #fff;
  color: #2ecc71;
  padding: 5px;
  -webkit-border-radius: 3px;
          border-radius: 3px; }

.post-card__time-text,
.post-card__time-icon {
  display: inline-block;
  vertical-align: middle;
  line-height: 14px; }

.related-item__text,
.related-item__icon {
  display: inline-block;
  vertical-align: middle;
  line-height: 14px; }

.related-item__text {
  opacity: 0.6; }

.post-card__elements li + li {
  margin-left: 20px; }

time {
  opacity: 0.6; }

.post-card__title {
  font-family: 'MullerMedium', Arial, sans-serif;
  font-size: 16px;
  line-height: 20px;
  padding: 22px 0 5px; }

/* end */
/* comments */
.comment-section {
  padding: 105px 0 100px; }

.comment-form-block {
  margin-top: 45px; }

.comment-textarea,
.comment-input {
  border: 1px solid #E0E1E1;
  -webkit-border-radius: 5px;
          border-radius: 5px;
  margin-bottom: 30px;
  padding: 0 20px; }

.form__info .comment-input,
.form__info .comment-textarea {
  border-width: 2px;
  border-color: #ccc; }

.comment-textarea {
  width: 100%;
  padding: 15px 20px 0 20px;
  height: 50px; }

.result-message {
  display: inline-block;
  vertical-align: middle;
  margin-left: 20px; }

.result-message.error {
  color: #f33712; }

.info-section .result-message {
  position: absolute;
  bottom: -30px;
  left: 0;
  right: 0;
  text-align: center; }

.comments-block {
  margin-top: 65px; }

.comment-list li {
  margin-top: 35px;
  padding-bottom: 28px;
  border-bottom: 1px solid #E0E0E1; }

.comment-list li .comment-author {
  font-family: 'MullerRegular', Arial, sans-serif;
  font-size: 18px;
  line-height: 18px;
  margin-bottom: 10px; }

.comment-list li .comment-time {
  font-size: 14px;
  line-height: 14px; }

.comment-list p {
  margin: 20px 0; }

/* end */
/* aside article */
.article-aside {
  max-width: 284px;
  width: 100%;
  padding: 0 15px;
  margin-top: 35px; }

.article-aside-block {
  background-image: url("../images/article/right-img.jpg");
  background-repeat: no-repeat;
  background-position: 50%;
  -webkit-background-size: cover;
          background-size: cover;
  padding: 70px 25px 90px;
  color: #fff; }

.article-aside-content {
  position: relative;
  z-index: 2;
  font-size: 18px;
  line-height: 24px; }

.article-aside__icon {
  margin-bottom: 20px; }

.article-aside__title {
  font-family: 'MullerMedium', Arial, sans-serif;
  margin-bottom: 15px;
  text-transform: uppercase; }

.article-aside__text {
  font-family: 'MullerRegular', Arial, sans-serif;
  margin-bottom: 20px; }

/* end */
.hidden {
  display: none; }

.hamburger {
  display: none; }

.btn_green_border {
  border: 1px solid #2ecc71;
  color: #313439;
  -webkit-transition: all .25s ease-in;
  -o-transition: all .25s ease-in;
  transition: all .25s ease-in;
  line-height: 46px; }

.btn_green_border:hover {
  background-color: #2ecc71;
  color: #fff; }

/* article modal windows */
.box-modal {
  position: relative;
  width: 570px; }

.arcticmodal-overlay {
  background-color: #404040 !important;
  opacity: .5 !important; }

.arcticmodal-close {
  position: absolute;
  right: -23px;
  top: -27px;
  width: 17px;
  cursor: pointer;
  height: 17px; }

.arcticmodal-close:hover:after,
.arcticmodal-close:hover:before {
  background-color: #d6d6d6;
    background-color--webkit-transition: background-color .2s ease-in;
    background-color-transition: background-color .2s ease-in; }

.arcticmodal-close:before,
.arcticmodal-close:after {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  content: ' ';
  height: 100%;
  width: 2px;
  opacity: 1;
  -webkit-transition: background-color .2s ease-out;
  -o-transition: background-color .2s ease-out;
  transition: background-color .2s ease-out;
  background-color: #fff; }

.arcticmodal-close:before {
  transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg); }

.arcticmodal-close:after {
  transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

.modal_window {
  width: 100%;
  max-width: 470px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  background-color: #fff; }

.modal_type_1 .modal_image img {
  border-radius: 3px 3px 0 0;
  -moz-border-radius: 3px 3px 0 0;
  -webkit-border-radius: 3px 3px 0 0; }

.modal_type_1 .modal_body {
  padding: 25px 30px 40px 30px; }

.modal_type_1 .modal_title {
  font-family: "MullerBold", Arial, sans-serif;
  font-size: 30px;
  margin-bottom: 20px; }

.modal_type_1 .modal_text {
  font-size: 14px;
  margin-bottom: 25px; }

.box-modal .input-item {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }

.modal_type_1 .input-item {
  max-width: 370px;
  margin-bottom: 10px; }

.form_notify {
  color: red;
  font-size: 14px;
  width: 100%;
  text-align: left;
  padding-left: 4px;
  margin-bottom: 10px;
  /*float: right*/ }

.modal_type_1 input[type="submit"] {
  width: 100%;
  max-width: 195px; }

.modal_type_2 {
  max-width: 870px; }

.modal_type_2 .box-modal {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex; }

.modal_type_2 .modal_image {
  -ms-flex-negative: 0;
    -ms-flex-negative-flex-shrink: 0;
  -ms-flex-shrink: 0;
  -webkit-flex-shrink: 0;
  border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  -webkit-border-radius: 3px 0 0 3px;
  width: 369px;
  background: url(../images/article/modal-2.jpg) center center no-repeat;
  background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover; }

.modal_type_2 .modal_body {
  padding: 50px 39px 66px 56px; }

.modal_type_2 .modal_title {
  font-family: 'MullerRegular', Arial, sans-serif;
  font-size: 24px;
  line-height: 1.2;
  margin-bottom: 20px; }

.modal_type_2 .modal_title a {
  font-family: 'MullerBold', Arial, sans-serif;
  color: #2ecc71; }

.modal_type_2 .modal_text {
  font-size: 16px;
  margin-bottom: 30px; }

.modal_type_2 .button {
  width: 100%;
  max-width: 370px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  padding-left: 15px;
  padding-right: 15px; }

.modal_type_2 .button_orange {
  margin-bottom: 20px; }

/* article modal windows end */
/* Blog page */
.blog_page .banner-section {
  text-align: left;
  color: #fefefe; }

.blog_content_section {
  padding-top: 15px;
  padding-bottom: 135px; }

.bs_blog_title {
  font-family: 'MullerBold', Arial, sans-serif;
  font-size: 60px;
  line-height: 1;
  margin-bottom: 20px; }

.bs_blog_text {
  font-family: 'RalewaySemiBold', Arial, sans-serif;
  font-size: 18px;
  margin-bottom: 25px; }

.blog_reg_btn {
  font-family: 'RalewayMedium', Arial, sans-serif;
  font-size: 16px;
  color: #fff;
  border-width: 2px;
  text-transform: none; }

.blog_page .banner-section {
  padding: 86px 0; }

.blog_wrap {
  width: 100%;
  -ms-flex-negative: 1;
    -ms-flex-negative-flex-shrink: 1; }

.blog_aside {
  -ms-flex-negative: 0;
    -ms-flex-negative-flex-shrink: 0; }

.blog_item {
  display: block;
  background-color: #fff;
  color: #313439;
  box-shadow: 0px 0px 25px 0px rgba(49, 52, 57, 0.05);
  -moz-box-shadow: 0px 0px 25px 0px rgba(49, 52, 57, 0.05);
  -webkit-box-shadow: 0px 0px 25px 0px rgba(49, 52, 57, 0.05);
  margin-bottom: 60px; }

.blog_item:hover .button_orange.dark_hover {
  background-color: #313439;
  border-color: #313439;
  color: #fff;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none; }

.blog_item:last-child {
  margin-bottom: 30px; }

.bi_img_block {
  position: relative;
  min-height: 75px; }

.bi_img_block .post-card__time {
  padding: 10px; }

.bi_img_block .post-card__time .post-card__time-icon {
  margin-right: 5px; }

.bi_content {
  padding: 40px 30px; }

.bi_title {
  font-family: 'MullerMedium', Arial, sans-serif;
  font-size: 24px; }

.bi_content .article-elements-list {
  font-size: 12px;
  margin-bottom: 25px; }

.bi_content .article-elements-list li {
  margin-right: 10px; }

.bi_content .article-elements-list svg {
  vertical-align: middle;
  fill: #adaeb0; }

.bi_content .article-elements-list li::after {
  background-color: #adaeb0;
  margin-left: 10px;
  height: 10px;
  position: relative;
  top: 1px; }

.article-elements-list li:last-child {
  opacity: inherit; }

.bi_text {
  font-size: 14px;
  max-width: 655px;
  margin-bottom: 15px; }

.blog_aside {
  width: 100%;
  max-width: 300px;
  padding: 0 15px; }

.ba_item:nth-child(1) {
  margin-bottom: 90px; }

.ba_item:nth-child(2) {
  margin-bottom: 130px; }

.ba_heading {
  font-family: 'MullerBold', Arial, sans-serif;
  font-size: 24px;
  margin-bottom: 30px; }

.ba_item:nth-child(2) .ba_heading {
  margin-bottom: 20px; }

.ba_blog_sections li {
  position: relative;
  padding-left: 20px;
  margin-bottom: 15px; }

.ba_blog_sections li:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto 0;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  background-color: #2ecc71;
  transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -webkit-transition: all .2s ease-in-out; }

.ba_blog_sections li a {
  position: relative;
  color: rgba(49, 52, 57, 0.6);
  transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -webkit-transition: all .2s ease-in-out; }

.ba_blog_sections li.current:before,
.ba_blog_sections li:hover:before {
  background-color: #313439; }

.ba_blog_sections li:hover a {
  color: #313439; }

.ba_blog_sections li.current a {
  color: #313439; }

.ba_blog_sections li.current a:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  height: 1px;
  width: 100px;
  background-color: #747578; }

.ba_popular_post {
  display: block;
  color: #313439;
  margin-bottom: 10px;
  margin-left: -10px;
  margin-right: -10px;
  padding: 10px;
  transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -webkit-transition: all .2s ease-in-out; }

.ba_popular_post:hover {
  background-color: #fff; }

.ba_popular_post img {
  width: 100px;
  float: left;
  margin-right: 20px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px; }

.ba_pp_title {
  font-family: 'MullerMedium', Arial, sans-serif;
  font-size: 14px;
  line-height: 1.3;
  padding-top: 5px; }

.ba_pp_publication_date {
  font-size: 12px;
  color: rgba(49, 52, 57, 0.6);
  line-height: 1.2; }

.ba_pp_views {
  display: inline-block;
  margin-right: 7px; }

.ba_pp_views svg {
  vertical-align: middle;
  fill: #a6a8aa; }

.ba_pp_views .article-elements__views-text {
  font-size: 12px;
  color: rgba(49, 52, 57, 0.6); }

.ba_pp_time_read .post-card__time-text {
  font-size: 13px;
  color: #2ecc71; }

.top3_offer_tabs {
  position: relative; }

.top3_offer_tabs_ua_ru {
  position: relative;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  height: 50px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border: 1px solid #d7d8d9;
  text-transform: uppercase;
  line-height: 50px;
  text-align: center;
  font-family: 'MullerBold', Arial, sans-serif;
  font-size: 16px;
  margin-bottom: 29px; }

.top3_offer_tab_title {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  height: 100%;
  width: 50%;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -webkit-transition: all .2s ease-in-out; }

.top3_offer_tab_title.active {
  box-shadow: 0px 5px 25px 0px rgba(46, 204, 113, 0.5);
  -moz-box-shadow: 0px 5px 25px 0px rgba(46, 204, 113, 0.5);
  -webkit-box-shadow: 0px 5px 25px 0px rgba(46, 204, 113, 0.5);
  background-color: #2ecc71;
  color: #fff; }

.top3_offer_tab_title:last-child {
  left: auto;
  right: 0; }

.top3_offer_tab_item {
  display: block; }

.top3_offer_item {
  display: block;
  color: #313439;
  background-color: #fff;
  padding: 30px 30px 38px 30px;
  border: 1px solid #eaebeb;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  margin-bottom: 20px;
  text-align: center; }

.top3_offer_item:last-child {
  margin-bottom: 0; }

.top3_offer_item:hover .button_green {
  background-color: transparent;
  color: #2ecc71;
  -webkit-transition: all .25s ease-in;
  -o-transition: all .25s ease-in;
  transition: all .25s ease-in;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none; }

.top3_offer_item img {
  display: inline-block !important;
  width: auto !important;
  max-width: 100%;
  margin-bottom: 10px; }

.top3_oi_title {
  font-family: 'MullerMedium', Arial, sans-serif;
  font-size: 18px;
  color: rgba(49, 52, 57, 0.6);
  margin-bottom: 3px; }

.top3_oi_price_item {
  margin-bottom: 20px; }

.top3_oi_price_item {
  float: left;
  width: 50%;
  text-align: left; }

.top3_oi_price_image {
  position: relative;
  top: -2px;
  width: 24px;
  height: 16px;
  vertical-align: middle;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border: 1px solid #d8d9da; }

.top3_oi_price {
  font-family: 'MullerBold', Arial, sans-serif;
  font-size: 18px;
  vertical-align: middle; }

.top3_oi_price_currency {
  font-size: 14px; }

.top3_offer_item .button_green {
  width: 100%;
  max-width: 210px;
  text-transform: none;
  font-family: 'RalewayMedium', Arial, sans-serif;
  font-size: 16px; }

/* Blog page end */
.section {
  padding: 85px 0 100px; }

.gray-sections {
  background-color: #f4f5f5; }

.top-products {
  background-image: url("../image/backgrounds/box.png");
  background-repeat: no-repeat;
  background-position: 220% 210px; }
  @media screen and (max-width: 1280px) {
    .top-products {
      background-position: 500px 220px; } }

.text-center {
  text-align: center; }

.h2 {
  font-family: "MullerBold", "Open Sans", sans-serif;
  font-size: 48px;
  line-height: 52px;
  color: #313439; }

.green-inner i {
  color: #2ecc71;
  font-style: normal; }

.orange-inner i {
  color: #f39c12;
  font-style: normal; }

.home-descr {
  font-family: "MullerRegular", "Open Sans", sans-serif;
  font-size: 20px; }

.button-group {
  margin-top: 55px; }
  @media screen and (max-width: 768px) {
    .button-group {
      margin-top: 35px; } }
  @media screen and (max-width: 576px) {
    .button-group {
      margin-top: 15px; } }

.button-group__item {
  padding: 0 50px;
  line-height: 50px;
  height: 50px;
  color: #313439;
  text-transform: uppercase;
  font-family: "MullerBold", "Open Sans", sans-serif;
  font-size: 16px;
  background-color: transparent;
  position: relative;
  border: 1px solid #e0dfdb;
  -webkit-border-radius: 3px;
          border-radius: 3px;
  -webkit-transition: all .25s ease-in;
  -o-transition: all .25s ease-in;
  transition: all .25s ease-in; }
  @media screen and (max-width: 576px) {
    .button-group__item {
      height: 40px;
      line-height: 40px;
      font-size: 14px;
      padding: 0 18px; } }
  .button-group__item.active {
    color: #ffffff;
    background-color: #f39c12;
    border: 1px solid #f39c12;
    z-index: 2;
    -webkit-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
    transition: all .25s ease-out; }
    .button-group__item.active:hover {
      color: #ffffff; }
  .button-group__item:last-child {
    left: -4px; }
  .button-group__item:hover {
    border: 1px solid #f39c12;
    color: #f39c12;
    -webkit-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
    transition: all .25s ease-out; }

.carousel .slick-arrow {
  font-size: 0;
  height: 26px;
  width: 26px;
  padding: 0;
  cursor: pointer;
  border-bottom-width: 0;
  border-left-width: 0;
  border-top-width: 7px;
  border-right-width: 7px;
  border-top-color: #313439;
  border-right-color: #313439;
  background-color: transparent;
  position: absolute;
  top: -webkit-calc(50% - 26px);
  top: calc(50% - 26px);
  -webkit-transition: all .25s ease-in;
  -o-transition: all .25s ease-in;
  transition: all .25s ease-in;
  z-index: 10; }
  .carousel .slick-arrow:hover {
    opacity: 0.7;
    -webkit-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
    transition: all .25s ease-out; }

.carousel .slick-prev {
  left: -85px; }

.carousel .slick-next {
  right: -85px; }

.carousel .slick-dots {
  text-align: center;
  margin-top: 70px;
  height: 15px;
  width: 100%;
  line-height: 15px; }
  @media screen and (max-width: 992px) {
    .carousel .slick-dots {
      margin-top: 30px; } }
  @media screen and (max-width: 576px) {
    .carousel .slick-dots {
      margin-top: 20px; } }
  .carousel .slick-dots li {
    display: inline-block;
    margin-right: 20px;
    height: 15px;
    line-height: 15px;
    vertical-align: top; }
    .carousel .slick-dots li:last-child {
      margin-right: 0; }
    .carousel .slick-dots li button {
      font-size: 0;
      vertical-align: top;
      width: 15px;
      height: 15px;
      background-color: transparent;
      border: 1px solid #2ecc71;
      cursor: pointer;
      -webkit-border-radius: 100%;
              border-radius: 100%;
      -webkit-transition: all .25s ease-in;
      -o-transition: all .25s ease-in;
      transition: all .25s ease-in; }
      .carousel .slick-dots li button:hover {
        background-color: #2ecc71;
        -webkit-transition: all .25s ease-out;
        -o-transition: all .25s ease-out;
        transition: all .25s ease-out; }
  .carousel .slick-dots .slick-active button {
    background-color: #2ecc71;
    -webkit-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
    transition: all .25s ease-out; }

.loader-outer {
  position: relative;
  width: 100%;
  height: 100%; }

.loader-inner {
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  top: -webkit-calc(50% - 8px);
  top: calc(50% - 8px);
  position: absolute;
  width: 100%;
  color: #2ecc71;
  left: 0;
  padding: 0;
  text-align: center; }
  .loader-inner i {
    font-style: normal;
    font-size: 16px;
    opacity: 0;
    display: inline-block;
    margin: 0 3px;
    width: 8px;
    height: 8px;
    background-color: #2ecc71;
    -webkit-border-radius: 100%;
            border-radius: 100%; }
    .loader-inner i:nth-child(6) {
      -webkit-animation: load 1.5s infinite ease-in-out;
              animation: load 1.5s infinite ease-in-out; }
    .loader-inner i:nth-child(5) {
      -webkit-animation: load 1.5s 100ms infinite ease-in-out;
              animation: load 1.5s 100ms infinite ease-in-out; }
    .loader-inner i:nth-child(4) {
      -webkit-animation: load 1.5s 200ms infinite ease-in-out;
              animation: load 1.5s 200ms infinite ease-in-out; }
    .loader-inner i:nth-child(3) {
      -webkit-animation: load 1.5s 300ms infinite ease-in-out;
              animation: load 1.5s 300ms infinite ease-in-out; }
    .loader-inner i:nth-child(2) {
      -webkit-animation: load 1.5s 400ms infinite ease-in-out;
              animation: load 1.5s 400ms infinite ease-in-out; }
    .loader-inner i:nth-child(1) {
      -webkit-animation: load 1.5s 500ms infinite ease-in-out;
              animation: load 1.5s 500ms infinite ease-in-out; }

@-webkit-keyframes load {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-65px);
            transform: translateX(-65px); }
  33% {
    opacity: 1;
    -webkit-transform: translateX(0px);
            transform: translateX(0px); }
  66% {
    opacity: 1;
    -webkit-transform: translateX(0px);
            transform: translateX(0px); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(65px);
            transform: translateX(65px); } }

@keyframes load {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-65px);
            transform: translateX(-65px); }
  33% {
    opacity: 1;
    -webkit-transform: translateX(0px);
            transform: translateX(0px); }
  66% {
    opacity: 1;
    -webkit-transform: translateX(0px);
            transform: translateX(0px); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(65px);
            transform: translateX(65px); } }

.product-carousel {
  margin-top: 50px; }
  @media screen and (max-width: 1310px) {
    .product-carousel {
      padding: 0 60px; } }
  @media screen and (max-width: 768px) {
    .product-carousel {
      margin-top: 30px; } }
  @media screen and (max-width: 576px) {
    .product-carousel {
      margin-top: 20px;
      padding: 0; } }
  .product-carousel .slick-list {
    padding: 10px 0; }

@media screen and (max-width: 1420px) {
  .product-carousel .slick-prev {
    left: -40px; } }

@media screen and (max-width: 576px) {
  .product-carousel .slick-prev {
    left: 12px; } }

@media screen and (max-width: 1420px) {
  .product-carousel .slick-next {
    right: -40px; } }

@media screen and (max-width: 576px) {
  .product-carousel .slick-next {
    right: 12px; } }

.product-item-container {
  width: 300px;
  padding: 0 15px; }

.product-item {
  background-color: #ffffff;
  -webkit-border-radius: 3px;
          border-radius: 3px;
  padding: 25px 28px 40px;
  border: 1px solid #e0e1e2;
  -webkit-transition: all .25s linear;
  -o-transition: all .25s linear;
  transition: all .25s linear;
  -webkit-animation: fadeIn  1s ease-in, zoomIn  1s ease-in;
          animation: fadeIn  1s ease-in, zoomIn  1s ease-in; }
  @media screen and (max-width: 1420px) {
    .product-item {
      padding: 25px 20px 40px; } }
  @media screen and (max-width: 576px) {
    .product-item {
      padding: 25px 30px 40px; } }
  .product-item:hover {
    border: 1px solid transparent;
    -webkit-box-shadow: 0px 0px 15px 8px rgba(0, 0, 0, 0.02);
            box-shadow: 0px 0px 15px 8px rgba(0, 0, 0, 0.02);
    -webkit-transition: all .25s linear;
    -o-transition: all .25s linear;
    transition: all .25s linear; }
  .product-item .button_green:hover {
    color: #2ecc71;
    background-color: transparent; }

.product-item__img {
  margin-bottom: 35px; }
  @media screen and (max-width: 576px) {
    .product-item__img {
      margin-bottom: 10px;
      max-width: 205px;
      margin: 0 auto 10px; } }

.product-item__name {
  color: #313439;
  opacity: 0.6;
  font-family: "MullerMedium", "Open Sans", sans-serif;
  font-size: 18px;
  line-height: 18px;
  margin-bottom: 15px; }
  @media screen and (max-width: 576px) {
    .product-item__name {
      margin-bottom: 10px; } }

.product-item__price {
  font-family: "MullerBold", "Open Sans", sans-serif;
  font-size: 18px;
  margin-bottom: 14px; }
  .product-item__price:last-child {
    margin-bottom: 0; }

.product-item__price-top,
.product-item__price-bottom {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center; }
  @media screen and (max-width: 576px) {
    .product-item__price-top,
    .product-item__price-bottom {
      margin-left: 8%; } }

.product-item__price-top {
  margin-bottom: 3px; }

.product-item__price-top-text {
  font-family: "MullerRegular", "Open Sans", sans-serif;
  line-height: 18px;
  margin-right: 7px; }
  @media screen and (max-width: 1420px) {
    .product-item__price-top-text {
      font-size: 15px;
      line-height: 15px; } }

@media screen and (max-width: 1420px) {
  .product-item__price-top-value {
    font-size: 15px;
    line-height: 15px; } }

.product-item__price-bottom-text {
  font-family: "MullerMedium", "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 16px;
  color: #5e5f63;
  margin-right: 4px; }
  @media screen and (max-width: 1420px) {
    .product-item__price-bottom-text {
      font-size: 13px;
      line-height: 13px; } }

.product-item__price-bottom-value {
  color: #2ecc71;
  font-family: "MullerBold", "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 16px; }
  @media screen and (max-width: 1420px) {
    .product-item__price-bottom-value {
      font-size: 13px;
      line-height: 13px; } }

.product-item__price span {
  font-size: 14px; }

.product-item__price-icon {
  margin-right: 7px;
  height: 18px; }
  .product-item__price-icon svg {
    border: 1px solid #d8d9da;
    -webkit-border-radius: 3px;
            border-radius: 3px; }

.product-item__price-group {
  margin-bottom: 36px; }
  @media screen and (max-width: 576px) {
    .product-item__price-group {
      margin-bottom: 20px; } }

.tab-content {
  position: relative; }

.tab-body {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  top: 0;
  left: 0;
  -webkit-transition: opacity .25s ease-in;
  -o-transition: opacity .25s ease-in;
  transition: opacity .25s ease-in;
  display: none; }
  .tab-body.active {
    display: block;
    position: static;
    opacity: 1;
    visibility: visible;
    -webkit-transition: opacity .25s ease-out;
    -o-transition: opacity .25s ease-out;
    transition: opacity .25s ease-out; }

.reviews-carousel {
  margin-top: 50px;
  max-width: 795px;
  margin-left: auto;
  margin-right: auto;
  position: relative; }
  @media screen and (max-width: 1310px) {
    .reviews-carousel {
      padding: 0 60px; } }
  @media screen and (max-width: 576px) {
    .reviews-carousel {
      margin-top: 50px;
      padding: 0 50px; } }
  @media screen and (max-width: 425px) {
    .reviews-carousel {
      padding: 0; } }

.reviews-carousel .slick-prev {
  left: -180px; }
  @media screen and (max-width: 1310px) {
    .reviews-carousel .slick-prev {
      left: -40px; } }
  @media screen and (max-width: 1200px) {
    .reviews-carousel .slick-prev {
      left: -60px; } }
  @media screen and (max-width: 425px) {
    .reviews-carousel .slick-prev {
      right: 37px;
      left: 0; } }

.reviews-carousel .slick-next {
  right: -180px; }
  @media screen and (max-width: 1310px) {
    .reviews-carousel .slick-next {
      right: -40px; } }
  @media screen and (max-width: 1200px) {
    .reviews-carousel .slick-next {
      right: -60px; } }
  @media screen and (max-width: 425px) {
    .reviews-carousel .slick-next {
      right: 0;
      left: 37px; } }

@media screen and (max-width: 425px) {
  .reviews-carousel .slick-prev,
  .reviews-carousel .slick-next {
    top: -35px;
    margin: 0 auto; } }

.reviews__item {
  max-width: 795px;
  margin: 0 auto; }

.youtube-wrap {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden; }

.youtube-wrap iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.teamwork-video-block {
  max-width: 800px;
  margin: 90px auto 0; }

.afterwork-section .button {
  min-width: 210px; }

.afterwork-block {
  max-width: 1030px;
  margin: 50px auto 75px; }
  .afterwork-block .col-md-6:nth-child(1) .afterwork__icon {
    background-image: url("../image/home/benefits-shdow-1.png");
    background-repeat: no-repeat;
    background-position: 23px 26px; }
  .afterwork-block .col-md-6:nth-child(2) .afterwork__icon {
    background-image: url("../image/home/benefits-shdow-2.png");
    background-repeat: no-repeat;
    background-position: 19px 23px; }
  .afterwork-block .col-md-6:nth-child(3) .afterwork__icon {
    background-image: url("../image/home/benefits-shdow-3.png");
    background-repeat: no-repeat;
    background-position: 17px 32px; }
  .afterwork-block .col-md-6:nth-child(4) .afterwork__icon {
    background-image: url("../image/home/benefits-shdow-4.png");
    background-repeat: no-repeat;
    background-position: 29px 21px; }
  .afterwork-block .col-md-6:nth-child(5) .afterwork__icon {
    background-image: url("../image/home/benefits-shdow-5.png");
    background-repeat: no-repeat;
    background-position: 23px 28px; }
  .afterwork-block .col-md-6:nth-child(6) .afterwork__icon {
    background-image: url("../image/home/benefits-shdow-6.png");
    background-repeat: no-repeat;
    background-position: 23px 21px; }
  .afterwork-block .col-md-6:nth-child(7) .afterwork__icon {
    background-image: url("../image/home/benefits-shdow-7.png");
    background-repeat: no-repeat;
    background-position: 28px 22px; }
  .afterwork-block .col-md-6:nth-child(8) .afterwork__icon {
    background-image: url("../image/home/benefits-shdow-8.png");
    background-repeat: no-repeat;
    background-position: 25px 26px; }

.afterwork__text {
  margin-bottom: 20px; }

.afterwork-group {
  margin-top: 27px; }

.afterwork__icon {
  width: 80px;
  height: 80px;
  line-height: 80px;
  -webkit-border-radius: 100%;
          border-radius: 100%;
  text-align: center; }
  .afterwork__icon svg {
    display: inline-block;
    vertical-align: middle; }

.afterwork__descr {
  width: -webkit-calc(100% - 100px);
  width: calc(100% - 100px); }

.brands-carousel {
  margin-top: 90px; }
  @media screen and (max-width: 1440px) {
    .brands-carousel {
      padding: 0 60px; } }

.brands-carousel__item {
  border-right: 1px solid #e0dfdb; }

.brands-carousel__img {
  text-align: center;
  height: 170px;
  line-height: 170px; }

.brands-carousel__img img {
  display: inline-block;
  vertical-align: middle; }

@media screen and (max-width: 1440px) {
  .brands-carousel .slick-prev {
    left: -40px; } }

@media screen and (max-width: 1440px) {
  .brands-carousel .slick-next {
    right: -40px; } }

.howitwork-block {
  margin-top: 90px; }
  @media screen and (max-width: 792px) {
    .howitwork-block {
      margin-top: 35px; } }
  @media screen and (max-width: 576px) {
    .howitwork-block {
      margin-top: 35px; } }

.howitwork__item {
  margin-top: 40px; }
  @media screen and (max-width: 792px) {
    .howitwork__item {
      margin-top: 20px; } }

.howitwork__title-main {
  opacity: .4;
  font-size: 48px;
  line-height: 48px;
  text-transform: uppercase;
  font-family: "MullerBold", "Open Sans", sans-serif; }
  @media screen and (max-width: 792px) {
    .howitwork__title-main {
      display: none; } }

.howitwork__counter {
  width: 70px;
  padding-left: 15px;
  font-family: "MullerBold", "Open Sans", sans-serif;
  font-size: 54px;
  line-height: 54px; }

.howitwork__text {
  width: -webkit-calc(100% - 70px);
  width: calc(100% - 70px); }

.howitwork__title {
  font-family: "MullerBold", "Open Sans", sans-serif;
  font-size: 18px;
  line-height: 24px;
  color: #313439;
  margin-bottom: 2px; }

.howitwork__descr {
  font-size: 14px; }

.bg-wrap {
  position: relative;
  overflow: hidden; }

#main {
  width: 100%;
  position: relative; }

#main img.bg {
  width: 100%; }

img.car {
  width: 52.14%;
  position: absolute;
  top: 41%;
  left: 0; }

img.wheel-l {
  position: absolute;
  width: 10.71%;
  bottom: 5.2%;
  left: -2.5%; }

img.wheel-r {
  position: absolute;
  width: 10.71%;
  bottom: 5.2%;
  left: 36.14%; }

/* Car animations */
.car-anim {
  -webkit-animation: car-anim .1s infinite linear;
          animation: car-anim .1s infinite linear; }

@-webkit-keyframes car-anim {
  from {
    -webkit-transform: rotate(0.4deg);
            transform: rotate(0.4deg); }
  to {
    -webkit-transform: rotate(-0.4deg);
            transform: rotate(-0.4deg); } }

@keyframes car-anim {
  from {
    -webkit-transform: rotate(0.4deg);
            transform: rotate(0.4deg); }
  to {
    -webkit-transform: rotate(-0.4deg);
            transform: rotate(-0.4deg); } }

img.trees {
  position: absolute;
  width: 16.14%;
  height: 68.18%;
  top: 10.36%;
  right: 4.28%; }

img.cloud {
  position: absolute; }

.cl1,
.cl2 {
  -webkit-animation: cloud-anim infinite linear;
          animation: cloud-anim infinite linear; }

.cl3, .cl5 {
  -webkit-animation: cloud-anim2 infinite linear;
          animation: cloud-anim2 infinite linear; }

.cl4 {
  -webkit-animation: cloud-anim3 infinite linear;
          animation: cloud-anim3 infinite linear; }

@-webkit-keyframes cloud-anim {
  from {
    left: -200px; }
  to {
    left: -webkit-calc(100% + 200px);
    left: calc(100% + 200px); } }

@keyframes cloud-anim {
  from {
    left: -200px; }
  to {
    left: -webkit-calc(100% + 200px);
    left: calc(100% + 200px); } }

@-webkit-keyframes cloud-anim2 {
  0% {
    left: 50%; }
  50% {
    left: -webkit-calc(100% + 250px);
    left: calc(100% + 250px); }
  50.001% {
    left: -250px; }
  100% {
    left: 50%; } }

@keyframes cloud-anim2 {
  0% {
    left: 50%; }
  50% {
    left: -webkit-calc(100% + 250px);
    left: calc(100% + 250px); }
  50.001% {
    left: -250px; }
  100% {
    left: 50%; } }

@-webkit-keyframes cloud-anim3 {
  0% {
    left: 75%; }
  25% {
    left: -webkit-calc(100% + 250px);
    left: calc(100% + 250px); }
  25.001% {
    left: -250px; }
  100% {
    left: 75%; } }

@keyframes cloud-anim3 {
  0% {
    left: 75%; }
  25% {
    left: -webkit-calc(100% + 250px);
    left: calc(100% + 250px); }
  25.001% {
    left: -250px; }
  100% {
    left: 75%; } }

.cl1 {
  width: 16.57%;
  height: 12.58%;
  top: 1.04%;
  left: 3.57%;
  -webkit-animation-duration: 70s;
          animation-duration: 70s; }

.cl2 {
  width: 10.07%;
  height: 9.44%;
  top: 0.7%;
  left: 3.57%;
  -webkit-animation-duration: 130s;
          animation-duration: 130s; }

.cl3 {
  width: 11.85%;
  height: 11.19%;
  top: 2.21%;
  left: 28.6%;
  -webkit-animation-duration: 140s;
          animation-duration: 140s; }

.cl4 {
  width: 16.57%;
  height: 12.58%;
  top: -5.5%;
  left: 3.57%;
  -webkit-animation-duration: 140s;
          animation-duration: 140s; }

.cl5 {
  width: 11.85%;
  height: 11.19%;
  top: 0.7%;
  left: 3.57%;
  -webkit-animation-duration: 70s;
          animation-duration: 70s; }

img.person1 {
  position: absolute;
  width: 17.64%;
  height: 71.15%;
  bottom: 0;
  right: 5.93%; }

img.person1-hand {
  position: absolute;
  width: 8.5%;
  height: 12.76%;
  bottom: 21.15%;
  right: 17.92%; }

/* Person1 animation */
img.person1-hand {
  -webkit-animation: person1-anim infinite 1.7s linear;
          animation: person1-anim infinite 1.7s linear; }

@-webkit-keyframes person1-anim {
  0% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
    -webkit-transform-origin: top right;
            transform-origin: top right; }
  50% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
    -webkit-transform-origin: top right;
            transform-origin: top right; }
  100% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
    -webkit-transform-origin: top right;
            transform-origin: top right; } }

@keyframes person1-anim {
  0% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
    -webkit-transform-origin: top right;
            transform-origin: top right; }
  50% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
    -webkit-transform-origin: top right;
            transform-origin: top right; }
  100% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
    -webkit-transform-origin: top right;
            transform-origin: top right; } }

.content-wrap {
  position: absolute;
  top: 0;
  left: -80%;
  width: 76.2%;
  -webkit-transition: left .5s linear .5s;
  -o-transition: left .5s linear .5s;
  transition: left .5s linear .5s; }

.content-wrap.on {
  left: 0; }

.content-wrap img.bg {
  position: absolute;
  top: 0; }

.content-wrap .mobile-column {
  display: none; }

.main-content {
  position: relative;
  padding: 6% 15.28% 10px 11.42%; }
  @media screen and (max-width: 792px) {
    .main-content {
      padding: 0; } }

.main-content h1 {
  color: #fff;
  font-size: 3vw;
  font-family: "MullerBold", "Open Sans", sans-serif;
  line-height: 3.4vw;
  margin-bottom: 2.2%; }
  @media screen and (max-width: 792px) {
    .main-content h1 {
      font-size: 32px;
      line-height: 34px;
      margin-bottom: 35px; } }
  @media screen and (max-width: 576px) {
    .main-content h1 {
      font-size: 23px;
      line-height: 29px;
      margin-bottom: 27px; } }

.title-descr {
  color: #fff;
  font-size: 1.4vw;
  line-height: 2.1vw;
  font-family: "MullerRegular", "Open Sans", sans-serif;
  margin-bottom: 30px; }
  @media screen and (max-width: 792px) {
    .title-descr {
      font-size: 14px;
      line-height: 18px; } }
  @media screen and (max-width: 576px) {
    .title-descr {
      font-size: 13px;
      line-height: 18px; } }

.btns-grp {
  margin-top: 3.54%; }

.hero__advantages-list {
  padding-left: 10px; }
  @media screen and (max-width: 792px) {
    .hero__advantages-list {
      margin-bottom: 40px;
      text-align: left;
      max-width: 369px;
      margin-left: auto;
      margin-right: auto; } }

.advantages-list__item {
  color: #ffffff;
  position: relative;
  margin-bottom: 0.8vw;
  font-size: 1.1vw;
  line-height: 1.7vw; }
  @media screen and (max-width: 792px) {
    .advantages-list__item {
      font-size: 14px;
      line-height: 21px;
      margin-bottom: 14px;
      padding-left: 27px; } }
  .advantages-list__item::before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    position: relative;
    top: 1px;
    left: 0;
    font-size: 1vw;
    width: 1.6vw;
    height: 1.6vw;
    -webkit-border-radius: 100%;
            border-radius: 100%;
    text-align: center;
    line-height: 1.6vw;
    background-color: #2ecc71;
    margin-right: 15px; }
    @media screen and (max-width: 792px) {
      .advantages-list__item::before {
        width: 16px;
        height: 16px;
        font-size: 11px;
        line-height: 17px;
        margin-right: 10px;
        position: absolute; } }
  .advantages-list__item::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0.5vw;
    left: 0.52vw;
    height: 0.8vw;
    width: 0.55vw;
    border-bottom: 0.2vw solid #ffffff;
    border-right: 0.2vw solid #ffffff;
    -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
            transform: rotate(45deg); }
    @media screen and (max-width: 792px) {
      .advantages-list__item::after {
        top: 4px;
        left: 5px;
        height: 8px;
        width: 5px;
        border-bottom: 2px solid #ffffff;
        border-right: 2px solid #ffffff; } }

.h2-section {
  margin-bottom: 15px; }

#main_footer {
  position: relative; }

#main_footer {
  width: 100%; }

#main_footer img.bg {
  width: 100%; }

#main_footer img.car {
  width: 54.14%;
  position: absolute;
  top: 42.3%;
  left: 5%; }

#main_footer img.wheel-l {
  position: absolute;
  width: 10.71%;
  bottom: 3.8%;
  left: 10%; }

#main_footer img.wheel-r {
  position: absolute;
  width: 10.71%;
  bottom: 3.8%;
  left: 40%; }

#main_footer img.person1 {
  position: absolute;
  width: 17.64%;
  height: 71.15%;
  bottom: 1%;
  left: 37%; }

#main_footer img.person1-hand {
  position: absolute;
  width: 8.5%;
  height: 12.76%;
  bottom: 22.15%;
  left: 34.1%; }

.text-block {
  position: absolute;
  top: 4.5%;
  width: 41.4%;
  left: 8%; }

.text-block img {
  width: 100%; }

.text-block span {
  position: absolute;
  top: 24%;
  left: 7%;
  font-size: 2.7vw;
  line-height: 2.9vw;
  font-family: "MullerBold", "Open Sans", sans-serif;
  color: #313439; }

.last-screen {
  min-height: 400px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
      -ms-flex-align: end;
          align-items: flex-end; }

@media screen and (max-width: 992px) {
  .second-screen-bg-group {
    display: none; } }

.form-block {
  position: absolute;
  right: 6vw;
  top: 50%;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  max-width: 470px;
  width: 100%; }
  @media screen and (max-width: 1180px) {
    .form-block {
      top: auto;
      bottom: -195px;
      right: 0; } }
  @media screen and (max-width: 1024px) {
    .form-block {
      bottom: -167px; } }
  @media screen and (max-width: 992px) {
    .form-block {
      position: static;
      margin: 0 auto;
      -webkit-transform: translateY(0);
          -ms-transform: translateY(0);
              transform: translateY(0);
      max-width: none; } }

.form-box {
  background-color: #ffffff;
  border-top: 5px solid #f39c12;
  padding: 35px 0;
  position: relative; }
  @media screen and (max-width: 1180px) {
    .form-box {
      padding: 20px 0; } }

.form-box .form {
  max-width: 470px;
  margin: 0 auto; }

.form-title__main {
  font-family: "MullerBold", "Open Sans", sans-serif;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  padding: 0 40px; }
  @media screen and (max-width: 1180px) {
    .form-title__main {
      font-size: 22px;
      line-height: 25px; } }

.form-input {
  margin-bottom: 10px;
  position: relative; }

.form-input input {
  font-size: 14px;
  line-height: 14px;
  width: 100%;
  border: 1px solid #e0dfdb;
  -webkit-border-radius: 3px;
          border-radius: 3px;
  padding: 11px 45px 11px 20px; }

.form-input__icon {
  text-align: center;
  position: absolute;
  top: 1px;
  bottom: 0;
  right: 1px;
  height: 100%;
  line-height: 42px;
  width: 40px; }

.form-input__icon svg {
  display: inline-block; }

.registration-from {
  padding: 18px 40px 25px;
  border-bottom: 1px solid #e0dfdb; }

.registration-social {
  padding: 35px 40px 10px;
  position: relative; }
  @media screen and (max-width: 576px) {
    .registration-social {
      padding: 15px 40px 10px; } }

.registration-social__descr-wrap {
  text-align: center;
  position: absolute;
  top: -15px;
  left: 0;
  right: 0;
  width: 100%; }

.registration-social__descr {
  padding: 0 30px;
  background-color: #ffffff;
  display: inline-block;
  font-size: 18px;
  line-height: 23px;
  font-family: "MullerMedium", "Open Sans", sans-serif; }
  @media screen and (max-width: 1180px) {
    .registration-social__descr {
      font-size: 16px;
      line-height: 20px; } }

.whywe-group {
  margin-top: 85px; }

.whywe-item {
  margin-bottom: 135px; }

.whywe-icon {
  background-color: #fff;
  -webkit-border-radius: 100%;
          border-radius: 100%;
  text-align: center;
  width: 300px;
  height: 300px;
  line-height: 300px; }

.whywe-icon svg {
  display: inline-block;
  vertical-align: middle; }

.whywe-text-wrap {
  position: relative; }

.whywe-text {
  background-color: #ffffff;
  padding: 35px 30px;
  -webkit-border-radius: 5px;
          border-radius: 5px;
  position: relative;
  z-index: 3; }
  .whywe-text_width-middle {
    max-width: 730px; }
  .whywe-text_padding {
    padding: 38px 30px; }
  .whywe-text_shadow-1 {
    background-image: url("../image/backgrounds/shadow-png-1.png"); }
  .whywe-text_shadow-2 {
    background-image: url("../image/backgrounds/shadow-png-2.png"); }
  .whywe-text_shadow-3 {
    background-image: url("../image/backgrounds/shadow-png-3.png"); }
  .whywe-text_shadow-4 {
    position: absolute;
    top: 0;
    left: 0; }
    .whywe-text_shadow-4 img {
      width: auto;
      max-width: none; }
      @media screen and (max-width: 1200px) {
        .whywe-text_shadow-4 img {
          display: none; } }
  .whywe-text_shadow {
    position: absolute;
    background-repeat: no-repeat;
    top: 0;
    left: 0;
    width: 1000%;
    height: 1000%; }
    @media screen and (max-width: 1200px) {
      .whywe-text_shadow {
        background-image: none; } }

.tobepartner {
  position: relative; }

.whywe__title {
  font-size: 24px;
  line-height: 24px;
  font-family: "RalewayBoldItalic", "Open Sans", sans-serif; }
  .whywe__title_blue {
    color: #01a9dc; }
  .whywe__title_green {
    color: #2ecc71; }
  .whywe__title_red {
    color: #d2555a; }

.whywe__list {
  margin-top: 40px; }

.whywe__list li {
  padding-left: 35px;
  position: relative;
  color: #515357;
  font-family: "RalewaySemiBold", "Open Sans", sans-serif; }
  .whywe__list li::before {
    content: '';
    position: absolute;
    top: 7px;
    left: 5px;
    border-right: 2px solid #515357;
    border-bottom: 2px solid #515357;
    width: 7px;
    height: 12px;
    -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
            transform: rotate(45deg); }
  .whywe__list li + li {
    margin-top: 20px; }

.whywe-section {
  overflow: hidden; }

.desktop-block {
  display: block; }
  @media screen and (max-width: 1200px) {
    .desktop-block {
      display: none; } }

.tablet-block {
  display: none; }
  @media screen and (max-width: 1200px) {
    .tablet-block {
      display: block; } }

.tobepartner-block {
  padding: 30px 15px;
  background-color: #fff;
  position: relative;
  -webkit-border-radius: 5px;
          border-radius: 5px;
  margin-bottom: 50px; }

.tobepartner__triangle {
  position: absolute;
  bottom: -10px;
  right: 0;
  width: 90px; }
  @media screen and (max-width: 480px) {
    .tobepartner__triangle {
      display: none; } }

.tobepartner__icon {
  text-align: center; }
  .tobepartner__icon svg {
    display: inline-block; }

.tobepartner__title {
  font-family: "RalewayMedium", "Open Sans", sans-serif;
  font-size: 24px;
  line-height: 28px;
  color: #f39c12;
  margin-bottom: 20px; }

.tobepartner__descr {
  max-width: 770px;
  width: 100%;
  font-family: "RalewaySemiBold", "Open Sans", sans-serif;
  color: #515357;
  margin-bottom: 30px; }

.tobepartner__button-block {
  position: relative; }
  .tobepartner__button-block .text {
    margin-bottom: 20px; }

.howitwork-group {
  max-width: 380px;
  width: -webkit-calc(33.3333% - 10px);
  width: calc(33.3333% - 10px);
  margin-right: 10px; }
  .howitwork-group_top {
    position: relative;
    top: -30px; }
  .howitwork-group_bottom {
    position: relative;
    bottom: -30px; }
  .howitwork-group:last-child {
    width: 30%; }

.howitwork-animation {
  padding: 35px 0;
  position: relative; }

.howitwork-circle {
  position: relative;
  z-index: 3;
  width: 90px;
  height: 120px;
  line-height: 120px;
  -webkit-border-radius: 100%;
          border-radius: 100%;
  text-align: center; }

.howitwork-circle svg {
  display: inline-block;
  vertical-align: middle; }

.howitwork-icon-animate {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%); }
  .howitwork-icon-animate_top {
    left: 63%; }
  .howitwork-icon-animate_bottom {
    right: 27%; }
  .howitwork-icon-animate svg {
    display: block; }

.howitwork-animation-bg {
  position: absolute;
  top: 30px;
  right: 0;
  width: -webkit-calc(100vw + 190px);
  width: calc(100vw + 190px);
  text-align: right;
  padding-right: 15px; }
  .howitwork-animation-bg img {
    display: inline-block;
    height: 182px; }

.wrapper-relative {
  position: relative; }

.howitwork-figura {
  display: inline-block;
  position: absolute;
  z-index: 3;
  right: 40%;
  top: 50%;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%); }
  .howitwork-figura .howitwork-figura__inner {
    position: absolute;
    top: 41px;
    text-align: center;
    left: 0;
    right: 0; }
    .howitwork-figura .howitwork-figura__inner img {
      display: inline-block; }

.howitwork-icon-animate {
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-duration: 2s;
          animation-duration: 2s; }

@-webkit-keyframes howitwork-icon-animate_top-1 {
  0% {
    opacity: 0;
    left: 80px; }
  10% {
    opacity: 1; }
  90% {
    opacity: 1; }
  100% {
    opacity: 0;
    left: 99%; } }

@keyframes howitwork-icon-animate_top-1 {
  0% {
    opacity: 0;
    left: 80px; }
  10% {
    opacity: 1; }
  90% {
    opacity: 1; }
  100% {
    opacity: 0;
    left: 99%; } }

.howitwork-icon-animate_top-1 {
  left: 99%;
  -webkit-animation-name: howitwork-icon-animate_top-1;
          animation-name: howitwork-icon-animate_top-1; }

@-webkit-keyframes howitwork-icon-animate_top-2 {
  0% {
    opacity: 0;
    left: 80px; }
  10% {
    opacity: 1; }
  90% {
    opacity: 1; }
  100% {
    opacity: 0;
    left: 98%; } }

@keyframes howitwork-icon-animate_top-2 {
  0% {
    opacity: 0;
    left: 80px; }
  10% {
    opacity: 1; }
  90% {
    opacity: 1; }
  100% {
    opacity: 0;
    left: 98%; } }

.howitwork-icon-animate_top-2 {
  left: 98%;
  -webkit-animation-name: howitwork-icon-animate_top-2;
          animation-name: howitwork-icon-animate_top-2; }

@-webkit-keyframes howitwork-icon-animate_top-3 {
  0% {
    opacity: 0;
    left: 80px; }
  10% {
    opacity: 1; }
  90% {
    opacity: 1; }
  100% {
    opacity: 0;
    left: 90%; } }

@keyframes howitwork-icon-animate_top-3 {
  0% {
    opacity: 0;
    left: 80px; }
  10% {
    opacity: 1; }
  90% {
    opacity: 1; }
  100% {
    opacity: 0;
    left: 90%; } }

.howitwork-icon-animate_top-3 {
  left: 90%;
  -webkit-animation-name: howitwork-icon-animate_top-3;
          animation-name: howitwork-icon-animate_top-3; }

@-webkit-keyframes howitwork-icon-animate_bottom-1 {
  0% {
    opacity: 0;
    right: -6%; }
  10% {
    opacity: 1; }
  90% {
    opacity: 1; }
  100% {
    opacity: 0;
    right: 66%; } }

@keyframes howitwork-icon-animate_bottom-1 {
  0% {
    opacity: 0;
    right: -6%; }
  10% {
    opacity: 1; }
  90% {
    opacity: 1; }
  100% {
    opacity: 0;
    right: 66%; } }

.howitwork-icon-animate_bottom-1 {
  right: 66%;
  -webkit-animation-name: howitwork-icon-animate_bottom-1;
          animation-name: howitwork-icon-animate_bottom-1; }

@-webkit-keyframes howitwork-icon-animate_bottom-2 {
  0% {
    opacity: 0;
    right: -7%; }
  10% {
    opacity: 1; }
  90% {
    opacity: 1; }
  100% {
    opacity: 0;
    right: 68%; } }

@keyframes howitwork-icon-animate_bottom-2 {
  0% {
    opacity: 0;
    right: -7%; }
  10% {
    opacity: 1; }
  90% {
    opacity: 1; }
  100% {
    opacity: 0;
    right: 68%; } }

.howitwork-icon-animate_bottom-2 {
  right: 68%;
  -webkit-animation-name: howitwork-icon-animate_bottom-2;
          animation-name: howitwork-icon-animate_bottom-2; }

@-webkit-keyframes howitwork-icon-animate_bottom-3 {
  0% {
    opacity: 0;
    right: 10%; }
  10% {
    opacity: 1; }
  90% {
    opacity: 1; }
  100% {
    opacity: 0;
    right: 68%; } }

@keyframes howitwork-icon-animate_bottom-3 {
  0% {
    opacity: 0;
    right: 10%; }
  10% {
    opacity: 1; }
  90% {
    opacity: 1; }
  100% {
    opacity: 0;
    right: 68%; } }

.howitwork-icon-animate_bottom-3 {
  right: 68%;
  -webkit-animation-name: howitwork-icon-animate_bottom-3;
          animation-name: howitwork-icon-animate_bottom-3; }

@-webkit-keyframes howitwork-figura__outer {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes howitwork-figura__outer {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.howitwork-figura__outer {
  -webkit-animation-name: howitwork-figura__outer;
          animation-name: howitwork-figura__outer;
  -webkit-animation-duration: 4s;
          animation-duration: 4s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear; }

.support {
  font-size: 12px;
  line-height: 12px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center; }
  @media screen and (max-width: 576px) {
    .support {
      -webkit-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
              flex-wrap: wrap; } }

.support__text {
  color: #ffffff;
  opacity: 0.6;
  margin-right: 10px; }

.support__icon {
  margin-right: 5px; }

.support__link {
  color: #ffffff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transition: all .25s linear;
  -o-transition: all .25s linear;
  transition: all .25s linear; }
  @media screen and (max-width: 576px) {
    .support__link {
      margin: 10px 0; } }
  @media screen and (max-width: 375px) {
    .support__link {
      margin-bottom: 0; } }
  .support__link:hover {
    opacity: 0.7;
    -webkit-transition: all .25s linear;
    -o-transition: all .25s linear;
    transition: all .25s linear; }

.footer-bottom-centered {
  max-width: 415px;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center; }
  @media screen and (max-width: 375px) {
    .footer-bottom-centered {
      -webkit-box-ordinal-group: 2;
      -webkit-order: 1;
          -ms-flex-order: 1;
              order: 1;
      -webkit-box-align: end;
      -webkit-align-items: flex-end;
          -ms-flex-align: end;
              align-items: flex-end;
      margin-bottom: 10px; } }

.title-product, .title-catalog {
  font-family: "MullerBold", "Open Sans", sans-serif;
  font-size: 48px;
  line-height: 52px;
  color: #313439;
  text-align: center;
  margin-bottom: 5px; }
  @media screen and (max-width: 576px) {
    .title-product, .title-catalog {
      font-size: 30px;
      line-height: 38px;
      margin: -10px 0; } }

.title-catalog {
  margin-bottom: 25px; }

.product-box {
  margin-top: 20px;
  padding: 30px 20px;
  -webkit-border-radius: 5px;
          border-radius: 5px;
  background-color: #ffffff;
  -webkit-box-shadow: 0px 5px 15px 2px rgba(0, 0, 0, 0.05);
          box-shadow: 0px 5px 15px 2px rgba(0, 0, 0, 0.05); }

.product-img {
  max-width: 290px;
  margin: auto; }
  @media screen and (max-width: 576px) {
    .product-img {
      max-width: 130px; } }

.product-info__top {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between; }

.product-info__in-stock {
  font-family: "RalewaySemiBold", "Open Sans", sans-serif;
  color: #2ecc71;
  background-color: #f4f5f5;
  padding: 0 13px;
  font-size: 13px;
  height: 40px;
  line-height: 40px;
  max-width: 150px;
  width: 100%;
  -webkit-border-radius: 5px;
          border-radius: 5px;
  text-align: center; }
  @media screen and (max-width: 576px) {
    .product-info__in-stock {
      max-width: 130px;
      margin-right: 10px;
      padding: 0 5px;
      font-size: 12px; } }

.product-info__top {
  margin-bottom: 20px; }

.product-info__price {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center; }
  @media screen and (max-width: 576px) {
    .product-info__price {
      -webkit-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
              flex-wrap: wrap; } }

.product-info__price-text {
  margin-right: 8px;
  font-family: "MullerBold", "Open Sans", sans-serif;
  color: #5e5f63;
  font-size: 16px;
  line-height: 18px; }
  @media screen and (max-width: 576px) {
    .product-info__price-text {
      width: 100%;
      margin-bottom: 15px; } }

.product-info__price {
  margin-bottom: 10px; }

.product-info__price-country {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  margin-right: 15px;
  color: #313439;
  font-size: 14px;
  line-height: 16px;
  font-family: "MullerBold", "Open Sans", sans-serif; }
  .product-info__price-country:last-child {
    margin-right: 0; }
  @media screen and (max-width: 576px) {
    .product-info__price-country {
      font-size: 18px;
      line-height: 20px; } }

.product-info__middle {
  margin-bottom: 12px; }

.call-centers__info {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex; }

.call-centers__text {
  margin-top: -5px;
  padding-right: 45px; }
  @media screen and (max-width: 576px) {
    .call-centers__text {
      line-height: 21px;
      padding-right: 0; } }

.call-centers th {
  font-family: "MullerMedium", "Open Sans", sans-serif;
  padding: 5px 0; }
  .call-centers th:first-child {
    width: 30.3%; }

.call-centers td {
  vertical-align: top;
  font-size: 14px; }

.web-centers {
  font-size: 14px; }

.web-centers th {
  font-family: "MullerBold", "Open Sans", sans-serif;
  opacity: 0.7; }
  .web-centers th:first-child {
    font-family: "MullerMedium", "Open Sans", sans-serif;
    opacity: 1; }

.web-centers td:first-child {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center; }
  @media screen and (max-width: 576px) {
    .web-centers td:first-child {
      -webkit-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
              flex-wrap: wrap; } }
  .web-centers td:first-child a {
    color: #2e92cc;
    display: inline-block;
    max-width: 155px;
    margin-right: 10px;
    line-height: 17px;
    word-break: break-word;
    text-decoration: underline;
    width: 110%;
    margin-bottom: 4px; }
    @media screen and (max-width: 576px) {
      .web-centers td:first-child a {
        font-size: 12px; } }
    .web-centers td:first-child a:hover {
      color: #2ecc71; }
  .web-centers td:first-child span {
    display: inline-block;
    padding: 5px;
    min-width: 75px;
    background-color: #f4f5f5;
    font-size: 12px;
    line-height: 12px;
    color: #2e92cc; }

.web-centers td,
.web-centers th {
  padding: 0px 20px; }
  @media screen and (max-width: 576px) {
    .web-centers td,
    .web-centers th {
      padding: 5px 15px; } }
  .web-centers td:first-child,
  .web-centers th:first-child {
    padding: 4px 0;
    max-width: 250px; }
  .web-centers td:nth-child(n+2),
  .web-centers th:nth-child(n+2) {
    font-family: "MullerBold", "Open Sans", sans-serif; }

.product-about {
  font-size: 14px;
  line-height: 18px;
  color: #515357; }

.product-about__title {
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 12px; }

.product-about__text {
  margin-bottom: 20px; }

.product-about__read-more-link {
  display: inline-block;
  border-bottom: 1px dashed #818284; }

.product-about__read-more-inner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  color: #818284;
  font-size: 12px;
  line-height: 12px;
  font-family: "MullerBold", "Open Sans", sans-serif;
  text-transform: uppercase;
  position: relative;
  margin-top: 15px; }

.product-about__character-table-wrap {
  overflow: hidden;
  height: 20px;
  position: relative; }
  .product-about__character-table-wrap::before {
    content: '';
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
    width: 100%;
    height: 40px;
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0)), to(#fff));
    background: -webkit-linear-gradient(rgba(255, 255, 255, 0), #fff);
    background: -o-linear-gradient(rgba(255, 255, 255, 0), #fff);
    background: linear-gradient(rgba(255, 255, 255, 0), #fff); }

.product-about__character-table-wrap.open {
  height: auto;
  z-index: 4; }
  .product-about__character-table-wrap.open::before {
    background: none; }

.product-about__read-more-icon {
  margin-left: 4px; }

.product-about__character-table td {
  padding: 0 0 15px;
  min-width: 100px; }

.product-box__about {
  position: relative;
  z-index: 3; }

.section-product {
  padding-bottom: 20px; }
  @media screen and (max-width: 576px) {
    .section-product {
      padding-top: 5px; } }

.product-box_padding-0 {
  padding: 0; }

@media screen and (max-width: 576px) {
  .additionally {
    overflow-x: auto; } }

.additionally table {
  width: 100%; }

.flex-table__head {
  -webkit-box-shadow: 0px 10px 18px -4px rgba(0, 0, 0, 0.07);
          box-shadow: 0px 10px 18px -4px rgba(0, 0, 0, 0.07);
  padding: 11px 20px 8px;
  text-transform: uppercase;
  opacity: 0.6;
  font-family: "MullerBold", "Open Sans", sans-serif; }
  @media screen and (max-width: 576px) {
    .flex-table__head {
      display: none; } }

.flex-table__body .flex-table__tr:nth-child(2n) {
  background-color: rgba(244, 245, 245, 0.6); }

.flex-table__tr {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex; }
  @media screen and (max-width: 576px) {
    .flex-table__tr {
      -webkit-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
              flex-wrap: wrap; } }

.flex-table__body .flex-table__tr {
  padding: 20px 20px 15px 20px;
  font-size: 14px; }

@media screen and (max-width: 576px) {
  .additionally-table__head {
    display: block; } }

.additionally-table__head .additionally-table__tr {
  display: none; }
  @media screen and (max-width: 576px) {
    .additionally-table__head .additionally-table__tr {
      display: block; } }

@media screen and (max-width: 576px) {
  .additionally-table__head .flex-table__tr {
    display: none; } }

.additionally-table__td:nth-child(1),
.additionally-table__th:nth-child(1) {
  -webkit-flex-basis: 295px;
      -ms-flex-preferred-size: 295px;
          flex-basis: 295px; }
  @media screen and (max-width: 576px) {
    .additionally-table__td:nth-child(1),
    .additionally-table__th:nth-child(1) {
      -webkit-flex-basis: 100%;
          -ms-flex-preferred-size: 100%;
              flex-basis: 100%;
      margin-bottom: 15px; } }

.additionally-table__th:nth-child(2),
.additionally-table__td:nth-child(2) {
  -webkit-flex-basis: 80px;
      -ms-flex-preferred-size: 80px;
          flex-basis: 80px;
  font-family: "MullerBold", "Open Sans", sans-serif;
  font-size: 16px; }
  @media screen and (max-width: 576px) {
    .additionally-table__th:nth-child(2),
    .additionally-table__td:nth-child(2) {
      -webkit-flex-basis: -webkit-calc(100%/3);
          -ms-flex-preferred-size: calc(100%/3);
              flex-basis: calc(100%/3); } }

.additionally-table__th:nth-child(3),
.additionally-table__td:nth-child(3) {
  -webkit-flex-basis: 80px;
      -ms-flex-preferred-size: 80px;
          flex-basis: 80px;
  font-family: "MullerBold", "Open Sans", sans-serif;
  font-size: 16px; }
  @media screen and (max-width: 576px) {
    .additionally-table__th:nth-child(3),
    .additionally-table__td:nth-child(3) {
      -webkit-flex-basis: -webkit-calc(100%/3);
          -ms-flex-preferred-size: calc(100%/3);
              flex-basis: calc(100%/3); } }

.additionally-table__th:nth-child(4),
.additionally-table__td:nth-child(4) {
  -webkit-flex-basis: 80px;
      -ms-flex-preferred-size: 80px;
          flex-basis: 80px;
  font-family: "MullerBold", "Open Sans", sans-serif;
  font-size: 16px; }
  @media screen and (max-width: 576px) {
    .additionally-table__th:nth-child(4),
    .additionally-table__td:nth-child(4) {
      -webkit-flex-basis: -webkit-calc(100%/3);
          -ms-flex-preferred-size: calc(100%/3);
              flex-basis: calc(100%/3); } }

.flex-table__td-as-th {
  display: none; }
  @media screen and (max-width: 576px) {
    .flex-table__td-as-th {
      display: block;
      text-transform: uppercase;
      opacity: 0.6;
      font-family: "MullerBold", "Open Sans", sans-serif;
      margin-bottom: 8px; } }

.additionally__price-1 {
  font-family: "MullerBold", "Open Sans", sans-serif; }
  .additionally__price-1 span {
    color: #5e5f63; }

.additionally__price-2 {
  font-family: "MullerRegular", "Open Sans", sans-serif; }

.additionally__price-3 {
  font-family: "MullerBold", "Open Sans", sans-serif;
  color: #818284; }
  .additionally__price-3 i {
    color: #2ecc71;
    font-style: normal; }

.additionally__price-1,
.additionally__price-2,
.additionally__price-3 {
  line-height: 21px; }

.additionally__countr-icon {
  margin-bottom: 10px; }

.countr-icon {
  display: inline-block;
  height: 18px; }
  .countr-icon svg {
    -webkit-border-radius: 3px;
            border-radius: 3px;
    border: 1px solid #d8d9da; }

.products-table__price svg {
  vertical-align: top; }

.product-box__head {
  -webkit-box-shadow: 0px 10px 18px -4px rgba(0, 0, 0, 0.07);
          box-shadow: 0px 10px 18px -4px rgba(0, 0, 0, 0.07);
  padding: 11px 20px 8px;
  text-transform: uppercase;
  opacity: 0.6;
  font-family: "MullerBold", "Open Sans", sans-serif; }

.product-box__body {
  padding: 0 20px; }

.geo-restrictions__country {
  overflow: hidden; }

.geo-restrictions__item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 20px 0; }

.geo-restrictions__text {
  margin-left: 20px;
  font-family: "MullerRegular", "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 18px; }

.related {
  overflow: hidden;
  height: 275px; }

.related-products {
  margin-top: 20px; }

.related-products .slick-dots {
  margin-top: 30px; }

.related__head {
  padding: 20px;
  border-bottom: 1px solid #e0dfdb; }

.related__title {
  font-family: "MullerBold", "Open Sans", sans-serif;
  font-size: 24px;
  line-height: 24px; }

.related__item {
  padding: 20px 20px 25px;
  border-right: 1px solid #e0dfdb;
  font-size: 18px;
  line-height: 18px; }

.related__img {
  text-align: center;
  margin-bottom: 10px;
  height: 85px;
  overflow: hidden; }

.related__img img {
  display: inline-block; }

.related__carousel {
  margin-right: -1px; }

.related__carousel .slick-arrow {
  position: absolute;
  top: -40px;
  right: 15px;
  cursor: pointer;
  -webkit-transition: opacity .25s linear;
  -o-transition: opacity .25s linear;
  transition: opacity .25s linear; }
  .related__carousel .slick-arrow:hover {
    opacity: 0.6;
    -webkit-transition: opacity .25s linear;
    -o-transition: opacity .25s linear;
    transition: opacity .25s linear; }

.related__carousel .slick-prev {
  right: 35px; }
  @media screen and (max-width: 1024px) {
    .related__carousel .slick-prev {
      right: 60px; } }

.related__name {
  font-family: "MullerRegular", "Open Sans", sans-serif;
  margin-bottom: 8px; }

.related__price {
  font-family: "MullerBold", "Open Sans", sans-serif;
  margin-bottom: 10px; }
  .related__price span {
    font-size: 14px; }

.related__result {
  color: #2ecc71;
  font-family: "MullerRegular", "Open Sans", sans-serif; }
  .related__result span {
    font-family: "MullerBold", "Open Sans", sans-serif; }

.grid-item:nth-child(even) .product-box {
  margin-left: 40px; }
  @media screen and (max-width: 1220px) {
    .grid-item:nth-child(even) .product-box {
      margin-left: 0; } }

.section-related {
  padding: 70px 0 100px; }

.catalog-nav {
  display: none; }
  @media screen and (max-width: 991px) {
    .catalog-nav {
      display: block; } }

.categories {
  border-top: 1px solid #e0dfdb;
  padding: 20px 0 0;
  -webkit-transition: margin .25s ease-in-out;
  -o-transition: margin .25s ease-in-out;
  transition: margin .25s ease-in-out; }
  @media screen and (max-width: 991px) {
    .categories {
      margin-bottom: 0;
      z-index: 7;
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: #fff;
      height: 100%;
      width: 100%;
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
      -webkit-transition: all .25s ease-in;
      -o-transition: all .25s ease-in;
      transition: all .25s ease-in; }
      .categories.open {
        opacity: 1;
        visibility: visible;
        pointer-events: auto;
        -webkit-transition: all .25s ease-out;
        -o-transition: all .25s ease-out;
        transition: all .25s ease-out; }
        .categories.open .button__close {
          display: block; } }
  .categories:after {
    content: '';
    display: block;
    height: 206px;
    margin-top: 20px;
    border-top: 1px solid #e0dfdb;
    background-color: #F4F5F5; }

.button__close {
  display: none; }
  @media screen and (max-width: 991px) {
    .button__close {
      position: fixed;
      right: 15px;
      top: 15px;
      cursor: pointer; } }

@media screen and (max-width: 991px) {
  .categories nav {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    height: 100%; } }

.category-menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between; }
  @media screen and (max-width: 991px) {
    .category-menu {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
          -ms-flex-direction: column;
              flex-direction: column;
      width: auto;
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
          -ms-flex-align: start;
              align-items: flex-start; } }

.category-menu__item {
  margin-right: 45px; }
  @media screen and (max-width: 1200px) {
    .category-menu__item {
      margin-right: 20px; } }
  @media screen and (max-width: 991px) {
    .category-menu__item {
      margin-right: 0;
      margin-bottom: 20px; } }
  .category-menu__item:last-child {
    margin-right: 0; }

.category-menu__link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transition: all .25s ease-in;
  -o-transition: all .25s ease-in;
  transition: all .25s ease-in; }
  .category-menu__link:hover .category-menu__icon {
    background-color: #f7ba5a;
    -webkit-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
    transition: all .25s ease-out; }
    .category-menu__link:hover .category-menu__icon svg {
      -webkit-transform: scale(0.85);
          -ms-transform: scale(0.85);
              transform: scale(0.85);
      -webkit-transition: all .25s ease-out;
      -o-transition: all .25s ease-out;
      transition: all .25s ease-out; }

.category-menu__icon {
  background-color: #f39c12;
  width: 60px;
  height: 60px;
  line-height: 60px;
  -webkit-border-radius: 100%;
          border-radius: 100%;
  text-align: center;
  margin-right: 10px;
  -webkit-transition: all .25s ease-in;
  -o-transition: all .25s ease-in;
  transition: all .25s ease-in; }
  .category-menu__icon svg {
    display: inline-block;
    vertical-align: middle;
    -webkit-transition: all .25s ease-in;
    -o-transition: all .25s ease-in;
    transition: all .25s ease-in; }

.category-menu__name {
  text-transform: uppercase;
  font-family: "MullerBold", "Open Sans", sans-serif;
  -webkit-transition: all .25s ease-in;
  -o-transition: all .25s ease-in;
  transition: all .25s ease-in; }
  .category-menu__name:hover {
    color: #f39c12;
    -webkit-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
    transition: all .25s ease-out; }
  @media screen and (max-width: 1200px) {
    .category-menu__name {
      font-size: 14px; } }

@media screen and (max-width: 991px) {
  .filter {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
        -ms-flex-align: start;
            align-items: flex-start;
    height: 100%;
    max-width: 490px;
    margin: 100px auto 0; } }

.filter-title {
  font-family: "MullerMedium", "Open Sans", sans-serif;
  font-size: 30px;
  line-height: 30px;
  color: #404041;
  margin-bottom: 15px;
  -webkit-transition: all .25s ease-in;
  -o-transition: all .25s ease-in;
  transition: all .25s ease-in; }
  @media screen and (max-width: 991px) {
    .filter-title {
      display: none; } }

.categories-filter {
  margin-top: -205px;
  padding: 30px 0;
  -webkit-box-shadow: 0px 10px 15px -10px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 10px 15px -10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  z-index: 5;
  -webkit-transition: padding .25s ease-in-out;
  -o-transition: padding .25s ease-in-out;
  transition: padding .25s ease-in-out; }
  .categories-filter.sticky-filter {
    margin-top: 0;
    position: fixed;
    top: -60px;
    left: 0;
    width: 100%;
    padding-bottom: 5px; }
  @media screen and (max-width: 991px) {
    .categories-filter {
      margin-top: 0;
      z-index: 7;
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: #fff;
      height: 100%;
      width: 100%;
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
      -webkit-transition: all .25s ease-in;
      -o-transition: all .25s ease-in;
      transition: all .25s ease-in; }
      .categories-filter.open {
        opacity: 1;
        visibility: visible;
        pointer-events: auto;
        -webkit-transition: all .25s ease-out;
        -o-transition: all .25s ease-out;
        transition: all .25s ease-out; }
        .categories-filter.open .button__close {
          display: block; } }

.button__filter {
  line-height: 40px;
  height: 40px;
  margin-top: 14px;
  background-color: #ffffff;
  color: #f39c12; }
  .button__filter:hover {
    color: #ffffff;
    background-color: #f39c12; }
  @media screen and (max-width: 768px) {
    .button__filter {
      margin-top: 20px; } }

.search {
  position: relative;
  width: 100%; }

.search__input {
  padding: 5px 45px 5px 20px; }

.search__button {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #f39c12;
  height: 100%;
  width: 40px;
  text-align: center;
  border: none;
  -webkit-border-top-right-radius: 5px;
          border-top-right-radius: 5px;
  -webkit-border-bottom-right-radius: 5px;
          border-bottom-right-radius: 5px; }

.search__button svg {
  display: inline-block;
  vertical-align: middle; }

.filter-element {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 10px; }
  @media screen and (max-width: 320px) {
    .filter-element {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
          -ms-flex-align: start;
              align-items: flex-start; } }

.filter-element__option {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  margin-right: auto;
  width: -webkit-calc(100% - 80px);
  width: calc(100% - 80px); }
  @media screen and (max-width: 991px) {
    .filter-element__option {
      width: 100%; } }

.filter-element__name {
  width: 80px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 80px;
      -ms-flex: 0 0 80px;
          flex: 0 0 80px;
  margin-right: 8px; }
  @media screen and (max-width: 991px) {
    .filter-element__name {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 70px;
          -ms-flex: 0 0 70px;
              flex: 0 0 70px; } }
  @media screen and (max-width: 320px) {
    .filter-element__name {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 auto;
          -ms-flex: 0 0 auto;
              flex: 0 0 auto; } }
  .filter-element__name_gender {
    width: auto;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    margin: 0;
    margin-right: 15px; }
    @media screen and (max-width: 991px) {
      .filter-element__name_gender {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 60px;
            -ms-flex: 0 0 60px;
                flex: 0 0 60px; } }
    @media screen and (max-width: 320px) {
      .filter-element__name_gender {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
            -ms-flex: 0 0 auto;
                flex: 0 0 auto; } }

.checkbox-input {
  margin-right: 15px; }
  @media screen and (max-width: 320px) {
    .checkbox-input:first-child {
      margin-left: -4px; } }

.input-element {
  height: 40px;
  padding: 5px 20px;
  -webkit-border-radius: 5px;
          border-radius: 5px;
  border: 1px solid #e0dfdb;
  width: 100%;
  max-width: 285px; }
  @media screen and (max-width: 991px) {
    .input-element {
      max-width: none; } }
  .input-element.field-error {
    border-color: red;
    position: relative; }
    .input-element.field-error:after {
      content: '\41F\440\438   \43F\43E\438\441\43A\435   \43F\43E   \446\435\43D\435   \432\44B\431\43E\440   \434\430\43D\43D\43E\433\43E   \43F\43E\43B\44F   \43E\431\44F\437\430\442\435\43B\435\43D';
      display: block;
      width: 100%;
      position: absolute;
      top: 45px;
      left: 0;
      color: red;
      font-size: 12px;
      line-height: 1.1; }

.sticky-filter .input-element.field-error:after {
  display: none; }

.filter-element__option-price {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  max-width: 285px; }
  @media screen and (max-width: 991px) {
    .filter-element__option-price {
      max-width: none; } }

.input-element__price {
  width: -webkit-calc((100% - 10px)/2);
  width: calc((100% - 10px)/2); }
  .input-element__price:first-child {
    margin-right: 10px; }

.select {
  width: 100%;
  height: 40px;
  line-height: 40px;
  border: 1px solid #e0dfdb; }
  .select::after {
    height: 7px;
    width: 7px;
    border-color: #313439; }

.nice-select .current {
  font-family: "RalewayMedium", "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 14px;
  color: #515357; }

.select.open .list {
  width: 100%; }

.select .option {
  font-family: "MullerBold", "Open Sans", sans-serif;
  font-size: 14px; }
  .select .option:hover {
    background-color: #f4f5f5; }
  .select .option:first-child {
    display: none; }

.nice-select:active,
.nice-select.open,
.nice-select:focus {
  border: solid 1px #e8e8e8; }

.select__text {
  height: 22px; }
  .select__text i {
    font-style: normal;
    color: #818284; }

.dropbtn i {
  font-style: normal;
  color: #818284; }

.sort-filter {
  margin-left: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  margin-bottom: 20px; }
  @media screen and (max-width: 991px) {
    .sort-filter {
      -webkit-box-pack: center;
      -webkit-justify-content: center;
          -ms-flex-pack: center;
              justify-content: center; } }
  @media screen and (max-width: 448px) {
    .sort-filter {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
          -ms-flex-direction: column;
              flex-direction: column; } }

.catalog-section {
  padding: 30px 0 100px; }

.sort-filter__item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center; }
  @media screen and (max-width: 448px) {
    .sort-filter__item {
      margin-bottom: 20px; } }
  .sort-filter__item:first-child {
    margin-right: 45px; }
    @media screen and (max-width: 448px) {
      .sort-filter__item:first-child {
        margin-right: 0; } }

.sort-filter__text {
  color: #313439;
  font-size: 14px;
  line-height: 1;
  margin-right: 10px; }

.sort-filter__select {
  background-color: transparent;
  border: none;
  line-height: 1;
  height: auto;
  width: auto;
  float: none;
  padding: 0;
  padding-right: 15px; }
  .sort-filter__select::after {
    right: 0; }
  .sort-filter__select:active, .sort-filter__select.open, .sort-filter__select:focus {
    border: none; }

.sort-filter__select .current {
  border-bottom: 1px dashed #515357; }

.sort-filter__select.open .list {
  width: auto; }

.catalog-nav-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 30px 0; }

.catalog-nav__button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  line-height: 1;
  padding-top: 6px;
  margin-right: 20px; }
  @media screen and (max-width: 840px) {
    .catalog-nav__button {
      line-height: 1 !important;
      padding: 3px 23px 0;
      margin-right: 15px; } }
  @media screen and (max-width: 320px) {
    .catalog-nav__button {
      padding: 3px 16px 0;
      margin-right: 10px; } }
  .catalog-nav__button:hover svg {
    fill: #f39c12; }

@-webkit-keyframes filter-menu {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes filter-menu {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.js-catalog-filter {
  margin-right: 0; }
  .js-catalog-filter.sticky-filter-menu {
    -webkit-animation-name: filter-menu;
            animation-name: filter-menu;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
    position: fixed;
    z-index: 1;
    top: 15px;
    right: 10px; }

.catalog-nav__icon {
  margin-right: 7px;
  display: block; }
  @media screen and (max-width: 480px) {
    .catalog-nav__icon svg {
      width: 21px; } }

.categories-filter.sticky-filter {
  font-size: 14px; }
  .categories-filter.sticky-filter .input-element {
    height: 35px;
    font-size: 14px; }
  .categories-filter.sticky-filter .button__filter {
    height: 35px;
    line-height: 35px;
    margin-top: 8px; }

.box-modal {
  -webkit-border-radius: 4px;
          border-radius: 4px;
  overflow: hidden;
  width: 570px;
  background-color: #ffffff; }
  @media screen and (max-width: 620px) {
    .box-modal {
      width: 100%; } }

.modal-head {
  height: 75px;
  border-bottom: 1px solid #e0dfdb;
  background-color: #fbfbfb;
  padding: 0 40px;
  font-family: "MullerRegular", "Open Sans", sans-serif;
  font-size: 18px;
  line-height: 18px; }
  @media screen and (max-width: 610px) {
    .modal-head {
      padding: 0 20px;
      font-size: 17px;
      line-height: 17px; } }

.modal-tab {
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center; }

.arcticmodal-close {
  right: 40px;
  top: 30px; }
  .arcticmodal-close::before, .arcticmodal-close::after {
    background-color: #32353a; }

.modal-tab__link {
  color: #2e92cc; }
  .modal-tab__link_active {
    font-family: "MullerBold", "Open Sans", sans-serif;
    font-size: 24px;
    line-height: 24px;
    color: #313439;
    cursor: default; }
    @media screen and (max-width: 610px) {
      .modal-tab__link_active {
        font-size: 20px;
        line-height: 20px; } }

.modal-tab__text {
  color: #818284;
  padding: 0 20px; }
  @media screen and (max-width: 610px) {
    .modal-tab__text {
      padding: 0 10px; } }

.form-body {
  padding: 30px 40px; }
  @media screen and (max-width: 610px) {
    .form-body {
      padding: 30px 20px; } }

.form__action {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 15px; }
  @media screen and (max-width: 420px) {
    .form__action {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
          -ms-flex-align: start;
              align-items: flex-start; } }

.form-auth__checkbox-input label::after {
  width: 6px !important;
  height: 9px !important;
  border-bottom: 2px solid #ffffff !important;
  border-right: 2px solid #ffffff !important;
  top: 5px !important;
  left: 7px !important; }

.form-auth__button {
  width: 155px;
  margin-right: 30px; }
  @media screen and (max-width: 420px) {
    .form-auth__button {
      margin-bottom: 10px; } }

.form-auth__recovery-passwoer {
  opacity: 0.7;
  border-bottom: 1px dashed #313439;
  font-size: 14px;
  line-height: 14px;
  -webkit-transition: all .25s ease-in;
  -o-transition: all .25s ease-in;
  transition: all .25s ease-in; }
  .form-auth__recovery-passwoer:hover {
    opacity: 1;
    -webkit-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
    transition: all .25s ease-out; }

.form__descr {
  display: inline-block;
  font-size: 18px;
  line-height: 23px;
  font-family: "MullerMedium", "Open Sans", sans-serif;
  position: relative;
  background-color: #fff;
  position: absolute;
  top: -13px;
  left: 50%;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
  white-space: nowrap; }

.form__descr_auth {
  padding: 0 60px; }

.form__descr_reg {
  padding: 0 20px; }

.form-auth__social {
  border-top: 1px solid #e0dfdb;
  position: relative;
  padding: 25px 40px 40px;
  text-align: center; }
  @media screen and (max-width: 610px) {
    .form-auth__social {
      padding: 10px 20px 30px; } }

.modal__button {
  margin: 0 10px; }
  @media screen and (max-width: 620px) {
    .modal__button {
      margin-top: 10px; } }

.form-label {
  font-size: 18px;
  margin-bottom: 5px;
  font-family: "MullerMedium", "Open Sans", sans-serif; }
  @media screen and (max-width: 578) {
    .form-label {
      font-size: 14px; } }

.form-registration__button {
  width: 210px; }

.form_validate .form-body {
  padding: 30px 40px 10px; }

.form__notification {
  margin-top: 5px;
  font-size: 14px;
  line-height: 30px;
  height: 30px; }
  .form__notification.text-danger {
    color: red; }

.products-table {
  -webkit-border-radius: 5px;
          border-radius: 5px;
  overflow: hidden;
  -webkit-box-shadow: 0px 5px 15px 2px rgba(0, 0, 0, 0.05);
          box-shadow: 0px 5px 15px 2px rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  width: 100%; }
  @media screen and (max-width: 840px) {
    .products-table {
      background-color: transparent;
      -webkit-box-shadow: none;
              box-shadow: none; } }

.products-table__head {
  -webkit-box-shadow: 0px 10px 18px -4px rgba(0, 0, 0, 0.07);
          box-shadow: 0px 10px 18px -4px rgba(0, 0, 0, 0.07);
  padding: 20px;
  color: rgba(49, 52, 57, 0.7);
  font-family: "MullerBold", "Open Sans", sans-serif;
  line-height: 14px;
  text-transform: uppercase; }
  @media screen and (max-width: 840px) {
    .products-table__head {
      display: none; } }

.products-table__subhead {
  color: rgba(49, 52, 57, 0.5);
  font-family: "MullerBold", "Open Sans", sans-serif;
  line-height: 14px;
  text-transform: uppercase;
  margin-bottom: 13px;
  display: none; }
  @media screen and (max-width: 840px) {
    .products-table__subhead {
      display: block;
      color: rgba(49, 52, 57, 0.7); } }

.products-table__body .products-table__tr {
  padding: 0 20px;
  border-bottom: 5px solid #f4f5f5; }
  .products-table__body .products-table__tr:last-child {
    border-bottom: none; }
  @media screen and (max-width: 840px) {
    .products-table__body .products-table__tr {
      padding: 20px;
      border-bottom: none; } }

.products-table__tr {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex; }
  @media screen and (max-width: 840px) {
    .products-table__tr {
      -webkit-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
              flex-wrap: wrap;
      margin-bottom: 20px;
      -webkit-box-shadow: 0px 5px 15px 2px rgba(0, 0, 0, 0.05);
              box-shadow: 0px 5px 15px 2px rgba(0, 0, 0, 0.05);
      -webkit-border-radius: 5px;
              border-radius: 5px;
      background-color: #ffffff; } }

.products-table__th-1,
.products-table__td-1 {
  -webkit-flex-basis: 225px;
      -ms-flex-preferred-size: 225px;
          flex-basis: 225px; }
  @media screen and (max-width: 534px) {
    .products-table__th-1,
    .products-table__td-1 {
      margin: 0 auto; } }

.products-table__th-2,
.products-table__td-2 {
  -webkit-flex-basis: 240px;
      -ms-flex-preferred-size: 240px;
          flex-basis: 240px; }
  @media screen and (max-width: 534px) {
    .products-table__th-2,
    .products-table__td-2 {
      -webkit-flex-basis: 100%;
          -ms-flex-preferred-size: 100%;
              flex-basis: 100%; } }

.products-table__th-3,
.products-table__td-3 {
  -webkit-flex-basis: 360px;
      -ms-flex-preferred-size: 360px;
          flex-basis: 360px; }
  @media screen and (max-width: 840px) {
    .products-table__th-3,
    .products-table__td-3 {
      -webkit-flex-basis: -webkit-calc(100%/2);
          -ms-flex-preferred-size: calc(100%/2);
              flex-basis: calc(100%/2); } }
  @media screen and (max-width: 570px) {
    .products-table__th-3,
    .products-table__td-3 {
      -webkit-flex-basis: 100%;
          -ms-flex-preferred-size: 100%;
              flex-basis: 100%; } }

.products-table__th-4,
.products-table__td-4 {
  -webkit-flex-basis: 100px;
      -ms-flex-preferred-size: 100px;
          flex-basis: 100px; }
  @media screen and (max-width: 840px) {
    .products-table__th-4,
    .products-table__td-4 {
      -webkit-flex-basis: -webkit-calc(50%/3);
          -ms-flex-preferred-size: calc(50%/3);
              flex-basis: calc(50%/3); } }
  @media screen and (max-width: 570px) {
    .products-table__th-4,
    .products-table__td-4 {
      -webkit-flex-basis: -webkit-calc(100%/3);
          -ms-flex-preferred-size: calc(100%/3);
              flex-basis: calc(100%/3); } }

.products-table__th-5,
.products-table__td-5 {
  -webkit-flex-basis: 115px;
      -ms-flex-preferred-size: 115px;
          flex-basis: 115px; }
  @media screen and (max-width: 840px) {
    .products-table__th-5,
    .products-table__td-5 {
      -webkit-flex-basis: -webkit-calc(50%/3);
          -ms-flex-preferred-size: calc(50%/3);
              flex-basis: calc(50%/3); } }
  @media screen and (max-width: 570px) {
    .products-table__th-5,
    .products-table__td-5 {
      -webkit-flex-basis: -webkit-calc(100%/3);
          -ms-flex-preferred-size: calc(100%/3);
              flex-basis: calc(100%/3); } }

.products-table__th-6,
.products-table__td-6 {
  -webkit-flex-basis: 125px;
      -ms-flex-preferred-size: 125px;
          flex-basis: 125px; }
  @media screen and (max-width: 840px) {
    .products-table__th-6,
    .products-table__td-6 {
      -webkit-flex-basis: -webkit-calc(50%/3);
          -ms-flex-preferred-size: calc(50%/3);
              flex-basis: calc(50%/3); } }
  @media screen and (max-width: 570px) {
    .products-table__th-6,
    .products-table__td-6 {
      -webkit-flex-basis: -webkit-calc(100%/3);
          -ms-flex-preferred-size: calc(100%/3);
              flex-basis: calc(100%/3); } }

.products-table__td-1 {
  padding: 30px 0; }
  @media screen and (max-width: 840px) {
    .products-table__td-1 {
      padding: 10px 0; } }

.products-table__td-2 {
  padding: 30px 0; }
  @media screen and (max-width: 840px) {
    .products-table__td-2 {
      padding: 10px 0; } }

.products-table__td-3 {
  padding: 30px 0; }
  @media screen and (max-width: 840px) {
    .products-table__td-3 {
      padding: 10px 0; } }

.products-table__td-4 {
  padding: 30px 0; }
  @media screen and (max-width: 840px) {
    .products-table__td-4 {
      padding: 10px 0; } }

.products-table__td-5 {
  padding: 30px 0; }
  @media screen and (max-width: 840px) {
    .products-table__td-5 {
      padding: 10px 0; } }

.products-table__td-6 {
  padding: 30px 0; }
  @media screen and (max-width: 840px) {
    .products-table__td-6 {
      padding: 10px 0; } }

.products-table__th-1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center; }

.products-table__th-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center; }

.products-table__th-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center; }

.products-table__th-4 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center; }

.products-table__th-5 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center; }

.products-table__th-6 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center; }

.sort-arrows {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-align-content: space-between;
      -ms-flex-line-pack: justify;
          align-content: space-between;
  height: 15px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  cursor: pointer; }

.sort-param {
  margin-right: 8px;
  position: relative;
  top: 1px; }

.products-table__td-4 {
  font-family: "MullerBold", "Open Sans", sans-serif; }

.products-table__td-5 {
  font-family: "MullerBold", "Open Sans", sans-serif; }

.products-table__td-6 {
  font-family: "MullerBold", "Open Sans", sans-serif; }

.products-table__img {
  margin-right: 15px; }
  @media screen and (max-width: 534px) {
    .products-table__img {
      margin-right: 0; } }

.products-table__name {
  font-family: "MullerRegular", "Open Sans", sans-serif;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 25px; }
  @media screen and (max-width: 570px) {
    .products-table__name {
      margin-bottom: 13px;
      font-size: 19px;
      line-height: 19px; } }

@media screen and (max-width: 534px) {
  .products-table__img img {
    margin: 0 auto; } }

@media screen and (max-width: 534px) {
  .products-table__info {
    text-align: center; } }

.products-table__price {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px; }
  @media screen and (max-width: 570px) {
    .products-table__price {
      margin-bottom: 13px; } }
  .products-table__price:last-child {
    margin-bottom: 0; }

.products-table__price-top {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex; }

.products-table__price-bottom {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  font-family: "MullerBold", "Open Sans", sans-serif; }

.products-table__price-top-text {
  font-size: 14px;
  line-height: 16px;
  font-family: "MullerRegular", "Open Sans", sans-serif;
  margin-right: 8px; }

.products-table__price-bottom-text {
  margin-right: 8px;
  color: rgba(49, 52, 57, 0.85); }

.products-table__price-bottom-value {
  color: #2ecc71;
  position: relative; }

.products-table__price-top-value {
  font-family: "MullerBold", "Open Sans", sans-serif;
  font-size: 18px;
  line-height: 18px;
  color: #313439; }
  .products-table__price-top-value span {
    font-size: 14px;
    line-height: 14px; }

.products-table__price-flag {
  margin-right: 13px; }

.load-more-action {
  margin-top: 40px;
  text-align: center; }

.btn-load-more {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  border: 1px solid #d0cfca;
  height: 70px;
  width: 210px;
  -webkit-border-radius: 60px;
          border-radius: 60px;
  background-color: #ffffff;
  -webkit-transition: all .25s ease-in;
  -o-transition: all .25s ease-in;
  transition: all .25s ease-in; }
  .btn-load-more:hover {
    -webkit-box-shadow: 0px 5px 15px 2px rgba(0, 0, 0, 0.05);
            box-shadow: 0px 5px 15px 2px rgba(0, 0, 0, 0.05);
    -webkit-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
    transition: all .25s ease-out; }
  .btn-load-more_loaded .btn-load-more__icon {
    -webkit-animation-name: loaded;
            animation-name: loaded;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    -webkit-animation-duration: 1s;
            animation-duration: 1s; }

@-webkit-keyframes loaded {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes loaded {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.btn-load-more__text {
  margin-right: 8px;
  font-family: "MullerRegular", "Open Sans", sans-serif; }

.btn-load-more__icon {
  height: 45px; }

.products-table__link {
  font-family: 'MullerBold', Arial, sans-serif;
  font-size: 14px;
  text-align: center;
  height: 40px;
  line-height: 40px;
  -webkit-border-radius: 25px;
          border-radius: 25px;
  color: #2ecc71;
  display: inline-block;
  vertical-align: middle;
  padding: 0 5px;
  width: 125px;
  text-transform: uppercase;
  border: 1px solid #2ecc71;
  cursor: pointer;
  -webkit-transition: all .25s ease-in;
  -o-transition: all .25s ease-in;
  transition: all .25s ease-in; }
  @media screen and (max-width: 570px) {
    .products-table__link {
      font-size: 13px;
      height: 35px;
      line-height: 35px; } }
  .products-table__link:hover {
    background-color: #2ecc71;
    color: #ffffff;
    -webkit-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
    transition: all .25s ease-out; }

.btn-info {
  position: relative; }
  .btn-info:hover .btn-info__text {
    opacity: 1;
    visibility: visible;
    -webkit-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
    transition: all .25s ease-out; }

.btn-info__icon {
  font-family: "MullerRegular", "Open Sans", sans-serif;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  vertical-align: middle;
  display: inline-block;
  width: 15px;
  height: 15px;
  -webkit-border-radius: 100%;
          border-radius: 100%;
  color: #818284;
  background-color: #e0e1e1;
  cursor: pointer; }

.btn-info__text {
  position: absolute;
  background-color: #ffffff;
  border: 1px solid #f5f5f6;
  -webkit-border-radius: 5px;
          border-radius: 5px;
  -webkit-box-shadow: 0px 5px 15px 2px rgba(0, 0, 0, 0.05);
          box-shadow: 0px 5px 15px 2px rgba(0, 0, 0, 0.05);
  width: 250px;
  padding: 7px 10px;
  font-family: "MullerRegular", "Open Sans", sans-serif;
  color: #818284;
  font-size: 13px;
  line-height: 16px;
  left: -15px;
  bottom: 30px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all .25s ease-in;
  -o-transition: all .25s ease-in;
  transition: all .25s ease-in; }
  @media screen and (max-width: 921px) {
    .btn-info__text {
      width: 27vw; } }
  @media screen and (max-width: 570px) {
    .btn-info__text {
      width: 250px;
      left: -215px; } }
  .btn-info__text::after, .btn-info__text::before {
    top: 100%;
    left: 14px;
    margin: auto;
    width: 0;
    height: 0;
    border-style: solid;
    content: '';
    position: absolute;
    display: block; }
  .btn-info__text::before {
    border-width: 12px 9px 0;
    border-color: rgba(0, 0, 0, 0.05) transparent transparent; }
    @media screen and (max-width: 570px) {
      .btn-info__text::before {
        left: auto;
        right: 16px; } }
  .btn-info__text::after {
    left: 15px;
    border-width: 10px 8px 0;
    border-color: #fff transparent transparent; }
    @media screen and (max-width: 570px) {
      .btn-info__text::after {
        left: auto;
        right: 17px; } }

.form-captcha {
  margin: 20px 0 30px;
  height: 70px;
  max-width: 280px;
  overflow: hidden;
  background-color: #f9f9f9; }

.restore-modal .form-body {
  padding: 20px 40px 30px; }

.d-block {
  display: block; }

/* responsive */
@media screen and (max-width: 1200px) {
  .product-item__price {
    font-size: 14px; }
  .top-products {
    background-position: 200% 190%;
    -webkit-background-size: 80% 80%;
            background-size: 80%; } }

@media screen and (max-width: 1024px) {
  .menu-header {
    margin-left: 0; } }

@media screen and (max-width: 992px) {
  .howitwork-animation {
    display: none; }
  .section {
    padding: 90px 0; }
  .h2 {
    font-size: 38px;
    line-height: 42px; }
  .article-wrap {
    width: 100%;
    max-width: none; }
  .article-inner p {
    margin: 25px 0; }
  .article-inner img {
    margin: 35px auto; }
  .advertising-image img {
    margin: 0; }
  .article-aside {
    max-width: none;
    width: 60%;
    padding: 0 15px;
    margin-top: 0;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    -webkit-box-ordinal-group: 0;
      -webkit-box-ordinal-group--ms-flex-order: -1;
        -webkit-box-ordinal-group--ms-flex-order-order: -1; }
  .article-aside-block {
    padding: 30px; }
  .article-aside-content {
    font-size: 16px;
    line-height: 20px; }
  .article-aside__title {
    margin-bottom: 17px; }
  .article-aside__text {
    margin-bottom: 20px; }
  .header-social-list {
    margin-right: 10px; } }

@media screen and (max-width: 940px) {
  .top3_offer_tab_item .owl-prev,
  .top3_offer_tab_item .owl-next {
    height: 100%;
    position: absolute;
    top: 55%;
    font-size: 50px !important; }
  .top3_offer_tab_item .owl-prev {
    left: 40%; }
  .top3_offer_tab_item .owl-next {
    right: 40%; } }

@media screen and (max-width: 915px) {
  .modal_type_2 .modal_title br {
    display: none; }
  .modal_type_2 {
    max-width: 470px; }
  .modal_type_2 .box-modal {
    display: block; }
  .modal_type_2 .modal_image {
    width: 100%;
    height: 200px;
    border-radius: 3px 3px 0 0;
    -moz-border-radius: 3px 3px 0 0;
    -webkit-border-radius: 3px 3px 0 0; }
  .modal_type_2 .modal_body {
    padding: 25px 30px 40px 30px; } }

@media screen and (max-width: 840px) {
  .hidden840 {
    display: none; }
  .visible840 {
    display: block !important; }
  .header {
    padding: 15px 0; }
  .button {
    line-height: 41px;
    height: 40px; }
  .with_shadow {
    line-height: 38px; }
  .btn_green_border {
    line-height: 36px; }
  .hamburger {
    display: inline-block;
    cursor: pointer;
    transition-property: opacity, -webkit-filter;
    -webkit-transition-property: opacity, -webkit-filter;
    -o-transition-property: opacity, filter;
    transition-property: opacity, filter;
    transition-property: opacity, filter, -webkit-filter;
    -webkit-transition-duration: 0.25s;
         -o-transition-duration: 0.25s;
            transition-duration: 0.25s;
    -webkit-transition-timing-function: linear;
         -o-transition-timing-function: linear;
            transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    position: relative;
    top: 6px;
    margin-right: 20px;
    overflow: visible; }
    .hamburger:hover {
      opacity: 0.7; }
    .hamburger.is-active:hover {
      opacity: 0.7; }
    .hamburger.is-active .hamburger-inner {
      background-color: #fff; }
    .hamburger.is-active::before, .hamburger.is-active::after {
      background-color: #fff; }
  .hamburger-box {
    width: 40px;
    height: 24px;
    display: inline-block;
    position: relative; }
  .hamburger-inner {
    display: block;
    top: 50%;
    width: 40px;
    height: 4px;
    background-color: #fff;
    -webkit-border-radius: 4px;
            border-radius: 4px;
    position: absolute;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    -o-transition-property: transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-duration: 0.15s;
         -o-transition-duration: 0.15s;
            transition-duration: 0.15s;
    -webkit-transition-timing-function: ease;
         -o-transition-timing-function: ease;
            transition-timing-function: ease; }
    .hamburger-inner::before, .hamburger-inner::after {
      width: 40px;
      height: 4px;
      background-color: #fff;
      -webkit-border-radius: 4px;
              border-radius: 4px;
      position: absolute;
      -webkit-transition-property: -webkit-transform;
      transition-property: -webkit-transform;
      -o-transition-property: transform;
      transition-property: transform;
      transition-property: transform, -webkit-transform;
      -webkit-transition-duration: 0.15s;
           -o-transition-duration: 0.15s;
              transition-duration: 0.15s;
      -webkit-transition-timing-function: ease;
           -o-transition-timing-function: ease;
              transition-timing-function: ease; }
    .hamburger-inner::before, .hamburger-inner::after {
      content: "";
      display: block; }
    .hamburger-inner::before {
      top: -10px; }
    .hamburger-inner::after {
      bottom: -10px; }
  .hamburger--slider .hamburger-inner {
    top: 2px; }
    .hamburger--slider .hamburger-inner::before {
      top: 10px;
      -webkit-transition-property: opacity, -webkit-transform;
      transition-property: opacity, -webkit-transform;
      -o-transition-property: transform, opacity;
      transition-property: transform, opacity;
      transition-property: transform, opacity, -webkit-transform;
      -webkit-transition-timing-function: ease;
           -o-transition-timing-function: ease;
              transition-timing-function: ease;
      -webkit-transition-duration: 0.25s;
           -o-transition-duration: 0.25s;
              transition-duration: 0.25s; }
    .hamburger--slider .hamburger-inner::after {
      top: 20px; }
  .hamburger--slider.is-active .hamburger-inner {
    -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
            transform: translate3d(0, 10px, 0) rotate(45deg); }
    .hamburger--slider.is-active .hamburger-inner::before {
      -webkit-transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
              transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
      opacity: 0; }
    .hamburger--slider.is-active .hamburger-inner::after {
      -webkit-transform: translate3d(0, -20px, 0) rotate(-90deg);
              transform: translate3d(0, -20px, 0) rotate(-90deg); }
  .menu-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    height: 100vh;
    width: 100%;
    z-index: 5;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all .25s ease-in;
    -o-transition: all .25s ease-in;
    transition: all .25s ease-in; }
  .header.open .header-logo {
    position: relative;
    z-index: 6; }
  .header.open .header-logo svg g path {
    fill: #333333 !important; }
  .header.open .header-logo svg g path:nth-of-type(6) {
    fill: #FFA84B !important; }
  .header.open .hamburger {
    z-index: 6; }
  .header.open .hamburger.is-active .hamburger-inner, .hamburger.is-active .hamburger-inner::before, .hamburger.is-active .hamburger-inner::after {
    background-color: #333333; }
  .header.open .button {
    position: relative;
    z-index: 6; }
  .header.open .menu-header {
    opacity: 1;
    visibility: visible;
    -webkit-transition: all .25s ease-in;
    -o-transition: all .25s ease-in;
    transition: all .25s ease-in; }
  .menu-header > ul {
    padding-top: 33%; }
  .menu-header ul li {
    display: block;
    text-align: center;
    border: none; }
    .menu-header ul li:before {
      top: 0;
      height: 100%; }
  .menu-header ul li + li {
    margin: 0;
    margin-top: 10px; }
  .menu-header ul li a {
    color: #333;
    font-size: 25px;
    line-height: 45px; }
  .menu-social-list {
    text-align: center;
    margin-top: 40px; }
  .menu-social-list .header-social-list {
    display: inline-block; }
  .menu-social-list .header-social-list li {
    display: inline-block;
    margin: 0;
    margin-left: 10px; }
  .related-item {
    width: 50%; } }

@media screen and (max-width: 792px) {
  body {
    font-size: 14px;
    line-height: 24px; }
  .banner-section {
    padding: 50px 0; }
  .logo svg {
    width: 100px; }
  .hamburger {
    margin: 0 0 0 14px; }
  .hamburger.is-active {
    -webkit-transition: margin .2s ease-in;
    -o-transition: margin .2s ease-in;
    transition: margin .2s ease-in; }
  .banner-item + .banner-item {
    margin-left: 0; }
  .raiting-post {
    width: 100%; }
  .banner-item {
    margin-bottom: 10px; }
  .article-aside {
    width: 100% !important; }
  .h1 {
    font-size: 20px;
    line-height: 30px; }
  .banner-items {
    margin-top: 10px; }
  .article-inner p {
    margin: 17px 0; }
  .article-inner ul {
    margin: 20px 0; }
  .input-item {
    height: 40px; }
  .raitings-list .star svg {
    width: 30px; }
  .article-inner h2 {
    margin-top: 30px;
    font-size: 20px;
    line-height: 25px; }
  .article-inner ul li + li {
    margin-top: 10px; }
  .advertising-item {
    padding: 35px 30px 255px 30px; }
  .advertising-item::before {
    top: auto;
    bottom: -85px; }
  .advertising-item h3 {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 0; }
  .article-inner p {
    margin: 10px 0; }
  .form-group .three-items {
    width: 100%;
    margin-bottom: 20px; }
  .form-group .three-items:last-child {
    margin-bottom: 0; }
  .info-section {
    margin-top: 40px; }
  .article-elements__bottom-item {
    width: 100%; }
  .article-elements__bottom-item:last-child {
    -webkit-box-ordinal-group: 0;
      -webkit-box-ordinal-group--ms-flex-order: -1;
        -webkit-box-ordinal-group--ms-flex-order-order: -1;
    margin-bottom: 30px; }
  .article-elements__bottom-raiting-title {
    margin-bottom: 5px; }
  .comment-form-group .two-items {
    width: 100%; }
  .comment-input {
    margin-bottom: 15px; }
  .result-message {
    margin-left: 0;
    margin-top: 10px;
    text-align: center;
    display: block; }
  .article-inner blockquote::before {
    top: 20px;
    left: 0;
    right: 0;
    margin: auto;
    width: 105px; }
  .article-inner blockquote {
    padding: 98px 15px 20px 15px;
    margin: 20px 0; }
  .article-inner h2 {
    font-size: 24px; }
  .article-elements-list li {
    margin-right: 5px; }
  .article-elements-list li::after {
    margin-left: 5px; }
  .article-elements__bottom-item .button_twitter {
    display: block; }
  .article-elements__bottom-item .button_twitter {
    margin-left: 0;
    margin-top: 10px; }
  .article-elements__bottom-like-title {
    font-size: 22px;
    line-height: 21px; }
  .article-elements__bottom-like-subtitle {
    font-size: 15px;
    line-height: 15px; }
  .h3 {
    font-size: 22px;
    line-height: 30px; }
  .related-item {
    width: 100%;
    margin-top: 40px; }
  .comment-section {
    padding: 70px 0 40px; }
  .comment-form-block {
    margin-top: 33px; }
  .comment-textarea,
  .comment-input {
    margin-bottom: 20px; }
  .comment-form-group .one-item:last-child {
    text-align: center; }
  .comment-list li {
    margin-top: 40px;
    padding-bottom: 0; }
  .pagination-nav ul li a,
  .pagination-nav ul li {
    height: 30px;
    width: 30px;
    line-height: 30px; }
  .pagination-nav ul li a {
    font-family: 'MullerMedium', Arial, sans-serif;
    font-size: 14px; }
  .pagination-nav .arrow-left,
  .pagination-nav .arrow-right {
    border-width: 0 2px 2px 0;
    padding: 8.4%; }
  .pagination-block {
    margin: 30px 0; }
  .footer-block .menu-footer ul li a:after {
    content: '';
    position: absolute;
    bottom: -11px;
    display: block;
    width: 100%;
    height: 1px;
    background-color: transparent;
    -webkit-transition: all .25s linear;
    -o-transition: all .25s linear;
    transition: all .25s linear; }
  .footer-block .menu-footer ul li a:hover:after {
    background-color: #f39c12;
    -webkit-transition: all .25s linear;
    -o-transition: all .25s linear;
    transition: all .25s linear; }
  .footer-block .menu-footer ul li:hover {
    border-color: transparent; }
  .footer-block__top a {
    display: block;
    width: 100%; }
  .footer-block__top .menu ul li a {
    display: inline-block;
    width: auto; }
  .related-posts-group {
    margin: 0 auto; }
  .related-posts-section {
    padding-top: 70px;
    padding-bottom: 50px; }
  .related-posts-group .owl-prev,
  .related-posts-group .owl-next {
    position: absolute;
    top: -50px;
    bottom: 0;
    font-size: 40px !important; }
  .owl-next {
    right: -8px; }
  .owl-prev {
    left: -8px; }
  .first-screen-bg-group {
    display: none; }
  .content-wrap__first {
    position: relative;
    width: 100%;
    text-align: center;
    padding: 100px 15px;
    background-image: url(../image/backgrounds/home-first.jpg);
    -webkit-background-size: cover;
            background-size: cover;
    background-position: center;
    background-repeat: no-repeat; }
    .content-wrap__first::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.5); }
  .form-title__main {
    font-size: 20px;
    line-height: 26px; }
  .whywe-item {
    margin-bottom: 60px; }
  .whywe-icon {
    margin-bottom: 30px;
    width: 200px;
    height: 200px;
    line-height: 200px; }
    .whywe-icon svg {
      width: 110px; }
  .whywe__title {
    font-size: 20px; }
  .whywe__list {
    margin-top: 30px; }
    .whywe__list li + li {
      margin-top: 10px; }
    .whywe__list li {
      line-height: 20px; } }

@media screen and (max-width: 768px) {
  .blog_container {
    display: block; }
  .blog_aside {
    text-align: center;
    max-width: 100%; }
  .ba_item {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    max-width: 270px;
    text-align: left; }
  .top3_offer_tab_item .owl-prev,
  .top3_offer_tab_item .owl-next {
    top: 0;
    bottom: 0;
    margin: auto 0;
    font-size: 40px !important; }
  .top3_offer_tab_item .owl-prev {
    left: -25px; }
  .top3_offer_tab_item .owl-next {
    right: -25px; } }

@media screen and (max-width: 576px) {
  .tobepartner-block {
    text-align: center; }
    .tobepartner-block::before, .tobepartner-block::after {
      display: none; }
  .tobepartner__icon {
    margin-bottom: 10px; }
    .tobepartner__icon svg {
      width: 130px; }
  .tobepartner__title {
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 10px; }
  .content-wrap {
    padding: 60px 15px; }
  .button-reg {
    margin: 10px auto 0;
    width: 100%;
    max-width: 200px;
    text-align: center; }
  .registration-social__descr {
    margin-bottom: 8px;
    font-size: 15px;
    line-height: 15px; }
  .h2 {
    font-size: 27px;
    line-height: 30px; }
  .teamwork-video-block {
    margin: 50px auto 0; }
  .afterwork-block {
    margin: 30px auto 55px; }
  .home-descr {
    font-size: 14px;
    margin-top: 5px; }
  .carousel .slick-dots {
    margin-top: 40px; }
    .carousel .slick-dots li {
      margin-right: 15px;
      margin-bottom: 10px; }
  .brands-carousel {
    margin-top: 40px; }
    .brands-carousel__item {
      border: none; }
    .brands-carousel__img {
      margin-top: 10px;
      height: auto;
      line-height: 1; }
  .howitwork__title-main {
    font-size: 27px;
    line-height: 39px; }
  .howitwork__item {
    margin-top: 25px; }
  .howitwork__counter {
    width: 37px;
    padding-left: 0;
    font-size: 35px;
    line-height: 35px; }
  .howitwork__title {
    font-size: 16px;
    line-height: 21px; } }

@media screen and (max-width: 540px) {
  .arcticmodal-close {
    right: 7px;
    top: 11px; }
  .arcticmodal-container_i2 {
    padding: 15px; } }

@media screen and (max-width: 430px) {
  .modal_type_1 .modal_title {
    font-size: 21px; }
  .modal_type_2 .button {
    font-size: 12px; }
  .modal_type_2 .modal_title {
    font-size: 19px; }
  .bi_title {
    font-size: 16px; }
  .bi_img_block .post-card__time {
    padding: 5px; }
  .bi_img_block .post-card__time {
    top: 15px;
    left: 15px; } }

@media screen and (max-width: 380px) {
  .modal_type_2 .button {
    line-height: 1.3;
    height: auto;
    padding: 15px 15px 10px 15px; }
  .modal_type_1 .modal_body {
    padding: 25px 15px 30px 15px; } }

@media screen and (max-height: 450px) {
  .menu-header > ul {
    padding-top: 10%; }
  .menu ul li {
    padding: 0; }
  .menu-header ul li + li {
    margin: 0; }
  .menu-social-list {
    margin-top: 20px; } }

/* end */
