.arcticmodal-overlay,
.arcticmodal-container {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000; }

.arcticmodal-container {
  overflow: auto;
  margin: 0;
  padding: 0;
  border: 0;
  border-collapse: collapse; }

*:first-child + html .arcticmodal-container {
  height: 100%; }

.arcticmodal-container_i {
  height: 100%;
  margin: 0 auto; }

.arcticmodal-container_i2 {
  padding: 24px;
  margin: 0;
  border: 0;
  vertical-align: middle; }

.arcticmodal-error {
  padding: 20px;
  -webkit-border-radius: 10px;
          border-radius: 10px;
  background: #000;
  color: #fff; }

.arcticmodal-loading {
  width: 80px;
  height: 80px;
  -webkit-border-radius: 10px;
          border-radius: 10px;
  background: #000 url(loading.gif) no-repeat 50% 50%; }
